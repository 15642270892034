import { useSearchParams } from "react-router-dom";
import useSWR from "swr";

import { api } from "@app/services";
import { useClients } from "@app/hooks/use-clients";
import { getFormErrors } from "@app/utils/get-form-errors";

export type PersonalInformation = {
	first_name: string;
	middle_names: string;
	last_name: string;
	email_address: string;
	contact_number: string;
	id_number: string;
	tax_number: string;
	id_document: string;
	id_selfie: string;
};

export type PersonalInformationUpdate = {
	first_name: string;
	middle_names: string;
	last_name: string;
	email_address: string;
	contact_number: string;
	id_number: string;
	tax_number: string;
};

export const usePersonalInformation = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { activeClientId } = useClients();

	const clientIdOverride = searchParams.get("clientId");
	const clientId = clientIdOverride ?? activeClientId;

	const { data, error, mutate } = useSWR<PersonalInformation>(
		clientId ? `/onboarding/${clientId}/personal-information/` : null,
	);

	const setClientIdInParams = (newClientId: string) => {
		const newParams = new URLSearchParams(searchParams);
		newParams.set("clientId", newClientId);
		setSearchParams(newParams);
	};

	const handleUpdate = async (
		newData: Partial<PersonalInformationUpdate>,
		clientIdOverride?: number,
	) => {
		try {
			await api.patch(
				`/onboarding/${clientIdOverride ?? clientId}/personal-information/`,
				newData,
			);
			mutate();
		} catch (error: any) {
			return getFormErrors(error);
		}
	};

	return {
		data,
		error,
		clientIdOverride: clientId,
		create: async (newData: Partial<PersonalInformationUpdate>) => {
			try {
				const { data } = await api.post("/clients/", newData);
				const clientID = data.client_id;
				setClientIdInParams(clientID);
				handleUpdate(newData, clientID);
			} catch (error: any) {
				return getFormErrors(error);
			}
		},
		update: handleUpdate,
		submit: async (newData: PersonalInformationUpdate) => {
			try {
				await api.put(`/onboarding/${clientId}/personal-information/`, newData);
				mutate();
			} catch (error: any) {
				return getFormErrors(error);
			}
		},
	};
};
