import { ResendEmail } from "@app/features/auth/resend-email";
import { FiAlertCircle } from "react-icons/fi";

import styles from "./index.module.css";
import { useUserProfile } from "../use-user-profile";
import { isChangeEmailAddressEnabled } from "@app/constants/feature-flags";
import { Button } from "@app/components/button";
import { useState } from "react";
import { Dialog } from "@app/components/dialog";
import { useUserSettings } from "@app/hooks/use-user-settings";

import cancelIconSrc from "./cancel.svg";
import dayjs from "dayjs";
import { useShowToast } from "@app/components/toasts/use-show-toast";

export const NewEmailNotVerfiedWarning = ({
	email,
	onResend,
}: {
	email: string;
	onResend?: () => Promise<boolean>;
}) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { invalidateChangeEmailLink } = useUserSettings();
	const [showCancelModal, setShowCancelModal] = useState(false);
	const { data, mutate } = useUserProfile();
	const [showToast] = useShowToast();

	const handleCancel = async () => {
		setIsSubmitting(true);
		const errors = await invalidateChangeEmailLink();
		if (errors) {
			showToast("Failed to cancel email change", "error");
			setIsSubmitting(false);
			return;
		}
		mutate();
		setIsSubmitting(false);
		setShowCancelModal(false);
	};

	return (
		<>
			<div className={styles.container}>
				<FiAlertCircle className={styles.icon} color="#FEC84B" size={24} />
				<div>
					<h3 className={styles.title}>New email not verified</h3>
					<p>
						We have sent an email to <strong>{email}</strong> to verify your new
						email address.
					</p>
					{isChangeEmailAddressEnabled && data?.email_change_expires_at && (
						<p>
							The email link will be valid until{" "}
							{dayjs(data.email_change_expires_at).format("HH:mm")}.
						</p>
					)}
					<div className={styles.actions}>
						{onResend && (
							<ResendEmail
								variant="change-email"
								email={email}
								onResend={onResend}
							/>
						)}
						{isChangeEmailAddressEnabled && (
							<>
								<div className={styles.divider} />
								<Button
									variant="tertiary"
									inline
									noPadding
									onClick={() => setShowCancelModal(true)}
								>
									Cancel
								</Button>
							</>
						)}
					</div>
				</div>
			</div>

			<Dialog
				isOpen={showCancelModal}
				onClose={() => setShowCancelModal(false)}
				actions={
					<>
						<Button
							className={styles.dialogButton}
							variant="secondary"
							disabled={isSubmitting}
							onClick={() => setShowCancelModal(false)}
						>
							Back
						</Button>
						<Button
							className={styles.dialogButton}
							variant="primary"
							onClick={handleCancel}
							disabled={isSubmitting}
						>
							Continue
						</Button>
					</>
				}
			>
				<div className={styles.dialogContent}>
					<img
						className={styles.dialogIcon}
						src={cancelIconSrc}
						alt=""
						width={60}
						height={60}
					/>
					<h1 className={styles.dialogTitle}>Cancel email change request?</h1>
					<p className={styles.dialogDescription}>
						This will cancel your email change request and keep{" "}
						<strong>{email}</strong> as your email address.
					</p>
				</div>
			</Dialog>
		</>
	);
};
