import { useSearchParams } from "react-router-dom";
import { AxiosProgressEvent } from "axios";
import useSWR from "swr";
import { saveAs } from "file-saver";

import { api } from "@app/services";
import { useClients } from "@app/hooks/use-clients";
import { getFilenameFromHeader } from "@app/utils/get-filename-from-header";
import { getFormErrors } from "@app/utils/get-form-errors";

export type OnboardingDocumentType =
	| "id_document"
	| "id_selfie"
	| "proof_of_address"
	| "registration_document"
	| "organogram_document"
	| "trading_proof_of_address";

export type Document = {
	document_id: string;
	name: string;
	document_type: string;
	created_at: string;
};

export const useDocuments = (
	type: OnboardingDocumentType,
	relatedEntityId?: number,
) => {
	const [searchParams] = useSearchParams();
	const { activeClientId } = useClients();
	const clientId = searchParams.get("clientId") ?? activeClientId;

	const { data, mutate, error, isLoading } = useSWR<Array<Document>>(
		clientId && type
			? relatedEntityId
				? `/related-entities/${relatedEntityId}/documents/?document_type=${type}`
				: `/clients/${clientId}/documents/?document_type=${type}`
			: null,
	);

	return {
		data: error ? undefined : data,
		isLoading,
		mutate,
		downloadDocument: async (documentId: string) => {
			const { data, headers } = await api.get(`/documents/${documentId}/`, {
				responseType: "blob",
			});
			const fileName = getFilenameFromHeader(headers, `${type}.pdf`);
			saveAs(data, fileName);
		},
		uploadDocument: async (
			file: File,
			onUploadProgress: (event: AxiosProgressEvent) => void,
		) => {
			const formData = new FormData();
			formData.append("file", file);
			formData.append("document_type", type);
			const uploadUrl = relatedEntityId
				? `/related-entities/${relatedEntityId}/documents/`
				: `/clients/${clientId}/documents/`;
			await api.post(uploadUrl, formData, {
				onUploadProgress,
			});
		},
		deleteDocument: async (documentId: string) => {
			try {
				await api.delete(`documents/${documentId}/`);
				await mutate();
			} catch (error) {
				return getFormErrors(error);
			}
		},
	};
};
