import { useState } from "react";

import { useMediaQuery } from "@app/hooks/use-media-query";
import { InputSwitch } from "@app/components/input-switch";
import { RadioGroup } from "@app/components/radio-group";
import styles from "../index.module.css";
import { OTPMethod, useOTP } from "@app/features/otp/use-otp";
import { EnableOTPDeviceFlow } from "@app/features/otp/enable-otp-device-flow";
import { DisableOTPDeviceFlow } from "@app/features/otp/disable-otp-device-flow";
import { ChangeOTPNumberFlow } from "@app/features/otp/change-otp-number-flow";
import { IconButton } from "@app/components/icon-button";
import { Button } from "@app/components/button";
import PenIcon from "@app/assets/images/pen-icon.svg?react";
import { formatPhoneNumber } from "@app/utils/format-phone-number";
import { isTrustDeviceSettingsEnabled } from "@app/constants/feature-flags";
import { TrustDeviceFlow } from "@app/features/otp/trust-device-flow";
import { UntrustDeviceFlow } from "@app/features/otp/untrust-device-flow";
import { useTrustDevice } from "./use-trust-device";
import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { useShowToast } from "@app/components/toasts/use-show-toast";

const DELIVERY_METHOD_OPTIONS = [
	{ label: "SMS", value: "sms" },
	{ label: "WhatsApp", value: "whatsapp" },
];

export const OtpAuthentication = () => {
	const [showToast] = useShowToast();
	const [isUpdatingChannel, setIsUpdatingChannel] = useState(false);
	const [showEnableOTPFlow, setShowEnableOTPFlow] = useState(false);
	const [showDisableOTPFlow, setShowDisableOTPFlow] = useState(false);
	const [showTrustDeviceFlow, setShowTrustDeviceFlow] = useState(false);
	const [showUntrustDeviceFlow, setShowUntrustDeviceFlow] = useState(false);
	const [showChangeOTPNumberFlow, setShowChangeOTPNumberFlow] = useState(false);
	const isMobile = useMediaQuery();
	const {
		isTrusted,
		mutate: mutateTrustDevice,
		isLoading: isTrustDeviceLoading,
	} = useTrustDevice();
	const { otpDetails, mutate: mutateOTP, changeChannel } = useOTP();

	const isOTPEnabled = otpDetails?.status === "enabled";

	const handleToggleOTP = () => {
		if (!isOTPEnabled) {
			setShowEnableOTPFlow(true);
		} else {
			setShowDisableOTPFlow(true);
		}
	};

	const handleToggleTrustDevice = () => {
		if (isTrusted) {
			setShowUntrustDeviceFlow(true);
		} else {
			setShowTrustDeviceFlow(true);
		}
	};

	const showOTPNumber = isOTPEnabled && !!otpDetails?.contact_number;
	const showOTPChannel = isOTPEnabled && otpDetails?.message_channel;
	const showTrustDevice = isTrustDeviceSettingsEnabled && isOTPEnabled;

	return (
		<>
			<div className={styles.subtitleContainer}>
				<h3 className={styles.subtitle}>OTP Authentication</h3>
				<div className={styles.inputSwitch}>
					<InputSwitch
						id="otpAuthentication"
						checked={isOTPEnabled}
						onChange={handleToggleOTP}
					/>
					<label
						className={styles.inputSwitchLabel}
						htmlFor="otpAuthentication"
					>
						{otpDetails?.status === "enabled" ? "Enabled" : "Disabled"}
					</label>
				</div>
			</div>

			{showOTPNumber && (
				<div className={styles.settingsRow}>
					<div>
						<p className={styles.settingsLabel}>
							Mobile Number (used for OTPs)
						</p>
						<p className={styles.settingsValue}>
							{formatPhoneNumber(otpDetails.contact_number)}
						</p>
					</div>
					{isMobile ? (
						<IconButton
							aria-label="Change OTP number"
							onClick={() => setShowChangeOTPNumberFlow(true)}
						>
							<PenIcon width={24} height={24} />
						</IconButton>
					) : (
						<Button
							className={styles.settingsButton}
							onClick={() => setShowChangeOTPNumberFlow(true)}
							variant="secondary"
						>
							Change OTP number
						</Button>
					)}
				</div>
			)}

			{showTrustDevice && (
				<div className={styles.settingsRow}>
					<div>
						<p className={styles.settingsLabel}>
							Trust this device
							<MoreInfoTooltip maxWidth={260} name="Trust this device">
								A trusted device doesn’t require an OTP for login.
							</MoreInfoTooltip>
						</p>
						<p className={styles.settingsValue}>{isTrusted ? "Yes" : "No"}</p>
					</div>
					{isMobile ? (
						<IconButton
							aria-label={isTrusted ? "Revoke trust" : "Trust this device"}
							disabled={isTrustDeviceLoading}
							onClick={handleToggleTrustDevice}
						>
							<PenIcon width={24} height={24} />
						</IconButton>
					) : (
						<Button
							disabled={isTrustDeviceLoading}
							className={styles.settingsButton}
							onClick={handleToggleTrustDevice}
							variant="secondary"
						>
							{isTrusted ? "Revoke trust" : "Trust this device"}
						</Button>
					)}
				</div>
			)}

			{showOTPChannel && (
				<div className={styles.settingsRow}>
					<div>
						<p className={styles.settingsLabel}>Default OTP Delivery Method</p>

						<RadioGroup
							name="delivery-method"
							disabled={isUpdatingChannel}
							value={otpDetails.message_channel}
							options={DELIVERY_METHOD_OPTIONS}
							onChange={async (method: OTPMethod) => {
								setIsUpdatingChannel(true);
								const errors = await changeChannel(method);
								setIsUpdatingChannel(false);
								if (errors && errors?.apiErrors.length > 0) {
									showToast(errors.apiErrors[0], "error");
									return;
								}
								showToast("OTP delivery method changed", "success");
							}}
						/>
					</div>
				</div>
			)}

			<EnableOTPDeviceFlow
				isOpen={showEnableOTPFlow}
				onComplete={() => {
					mutateOTP();
					setShowEnableOTPFlow(false);
				}}
				onClose={() => {
					setShowEnableOTPFlow(false);
				}}
			/>

			<DisableOTPDeviceFlow
				isOpen={showDisableOTPFlow}
				onComplete={() => {
					mutateOTP();
					setShowDisableOTPFlow(false);
				}}
				onClose={() => {
					setShowDisableOTPFlow(false);
				}}
			/>

			<ChangeOTPNumberFlow
				isOpen={showChangeOTPNumberFlow}
				onClose={() => {
					setShowChangeOTPNumberFlow(false);
				}}
				onComplete={() => {
					mutateOTP();
					setShowChangeOTPNumberFlow(false);
				}}
			/>

			<TrustDeviceFlow
				isOpen={showTrustDeviceFlow}
				variant="settings"
				onComplete={() => {
					mutateTrustDevice();
					setShowTrustDeviceFlow(false);
				}}
			/>

			<UntrustDeviceFlow
				isOpen={showUntrustDeviceFlow}
				onComplete={() => {
					mutateTrustDevice();
					setShowUntrustDeviceFlow(false);
				}}
			/>
		</>
	);
};
