import styles from "./toast-content.module.css";
import { ReactNode } from "react";
import { ToastTheme } from "./toast-variants";

export const ToastContent = ({
	theme,
	content,
}: {
	theme: ToastTheme;
	content?: ReactNode;
}) => {
	return (
		<div className={styles.container}>
			{theme.icon}
			{content}
		</div>
	);
};
