import { useMemo } from "react";
import useSWR from "swr";

export const useListPaymentBops = (
	paymentId?: number,
	query?: {
		search_query?: string;
		bop_category_group?: string;
		ordering?: string;
		limit?: number;
		offset?: number;
	},
) => {
	const queryParams = useMemo(() => {
		const params = [`limit=${1000}`, `offset=${0}`];
		if (!query) return params;

		if (query.search_query) params.push(`search_query=${query.search_query}`);

		if (query.bop_category_group)
			params.push(`bop_category_group=${query.bop_category_group}`);

		if (query.ordering && query.ordering !== "null")
			params.push(`ordering=${query.ordering}`);

		return params;
	}, [query]);

	const { data, isLoading, error, mutate } = useSWR<{
		count: number;
		items: {
			id: number;
			bop_code: string;
			category: string;
			description: string;
		}[];
	}>(paymentId ? `payments/${paymentId}/bops/?${queryParams.join("&")}` : null);

	const paymentBops = useMemo(
		() =>
			data?.items.map((item) => ({
				id: item.id,
				bopCode: item.bop_code,
				category: item.category,
				description: item.description,
			})),
		[data],
	);

	return {
		paymentBopsCount: data?.count,
		paymentBops,
		isLoading,
		error,
		mutate,
	};
};
