import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { EditNicknameModalView } from "./edit-nickname-modal-view";

import { useMediaQuery } from "@app/hooks/use-media-query";
import { api, type MappedReasons } from "@app/services";
import { FormInputProps } from "@app/components/form-builder/types";
import { getFormErrors } from "@app/utils/get-form-errors";

interface NicknameForm {
	nickname: string;
}

export const EditNicknameModal = (props: {
	isOpen: boolean;
	isEditNickname?: boolean;
	loading?: boolean;
	nickname?: string;
	recipientId?: number;
	onNicknameSaved: () => void;
	onCancel: () => void;
}) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const isMobile = useMediaQuery();

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors, isValid, isDirty },
	} = useForm<NicknameForm>({
		defaultValues: { nickname: props.nickname ?? "" },
		mode: "onTouched",
	});

	const [mappedReasons, setMappedReasons] = useState<
		MappedReasons | undefined
	>();

	const onSaveNickname = async (formData: NicknameForm) => {
		if (props.recipientId) {
			try {
				await api.put(`recipients/${props.recipientId}/update_nickname/`, {
					nickname: formData.nickname,
				});
				props.onNicknameSaved();
				setMappedReasons(undefined);
			} catch (error) {
				const formErrors = getFormErrors(error);
				setMappedReasons(formErrors.mappedReasons);
			}
		}
	};

	useEffect(() => {
		if (props.isOpen && props.nickname) {
			reset({ nickname: props.nickname });
		}
	}, [props.isOpen, props.nickname, reset]);

	const NicknameInputFields: FormInputProps[][] = [
		[
			{
				className: "edit-nickname-input",
				name: "nickname",
				placeholder: "eg. Travel account",
				required: true,
				theme: "none",
				title: "Nickname",
			},
		],
	];

	const viewProps = {
		...props,
		errors: errors,
		formControl: control,
		formInputs: NicknameInputFields,
		isValid: isValid,
		isDirty: isDirty,
		loading: false,
		mappedReasons,
		title: "Nickname",
		handleSubmit: handleSubmit(
			(data) => {
				onSaveNickname(data);
			},
			(data) => {},
		),
	};

	return (
		<EditNicknameModalView
			{...viewProps}
			isOpen={props.isOpen}
			isFullScreen={isMobile}
		/>
	);
};
