import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { paths } from "@app/constants/paths";
import { useSetCurrentPage } from "@app/hooks/use-set-payment-current-page";
import { useStateWithCallback } from "@app/hooks/use-state-with-callback";

import { BalanceOfPaymentView } from "./balance-of-payment-view";

import { useAccountManager } from "@app/hooks/use-account-manager";
import { tempPaymentStatusMapping } from "@app/hooks/use-payment-status";
import { useSupportingDocuments } from "@app/hooks/use-supporting-documents";
import { useTransaction } from "@app/hooks/use-transaction";
import { useTransactionId } from "@app/hooks/use-transaction-id";
import type { PaymentBopQueryParams } from "@app/services";
import { useSubmittedTransactionRedirect } from "../use-submitted-transaction-redirect";
import { handleGeneralError } from "@app/utils/handle-general-error";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { usePaymentBop } from "./use-payment-bop";
import { usePaymentBopOptions } from "./use-payment-bop-options";
import { useShowToast } from "@app/components/toasts/use-show-toast";
import { useListPaymentBops } from "./use-list-payment-bops";
import { useSuggestedPaymentBops } from "./use-suggested-payment-bops";
interface BalanceOfPaymentState {
	existingBopOption?: number;
	selectedBopOption?: number;
	queryParams: PaymentBopQueryParams;
}

interface BalanceOfPaymentLogicState {
	bopChangedNavigationPath?: string;
	currentPage?: number;
	showBopChangedModal?: boolean;
	showUnselectedModal?: boolean;
}

const tableId = "bop-table";
const selectedRowClassName = "selected-row";

export const BalanceOfPayment = () => {
	const [isSaving, setIsSaving] = useState(false);
	const navigate = useNavigate();
	const isMobile = useMediaQuery();

	const { data: accountManager } = useAccountManager();
	const transactionId = useTransactionId();
	const [showToast] = useShowToast();
	const {
		exchangeDetails,
		paymentStatus,
		activePaymentId,
		isPaymentStatusLoading,
		mutatePaymentStatus,
	} = useTransaction(transactionId);
	const {
		data: paymentSupportingDocs,
		isLoading: isSupportingDocumentsLoading,
	} = useSupportingDocuments(activePaymentId);

	const paymentId = exchangeDetails?.payment_ids[0];

	const {
		paymentBop,
		isLoading: isPaymentBopLoading,
		updatePaymentBop,
	} = usePaymentBop(paymentId);

	const { suggestedPaymentBops, isLoading: isSuggestedPaymentBopsLoading } =
		useSuggestedPaymentBops(paymentId);

	const { data: paymentBopOptions, isLoading: isPaymentBopOptionsLoading } =
		usePaymentBopOptions(paymentId);

	useSubmittedTransactionRedirect();
	useSetCurrentPage(paymentId, "bop");

	const hasUploadedDocuments = paymentSupportingDocs?.some(
		(type) => type?.documents?.length > 0,
	);

	const defaultState: BalanceOfPaymentState = {
		existingBopOption: undefined,
		selectedBopOption: undefined,
		queryParams: {
			payment_bop: {
				limit: 10,
				offset: 0,
			},
			payment_id: undefined,
		},
	};
	const [state, setState] = useState<BalanceOfPaymentState>(defaultState);

	const [bopPendingConfirmation, setBopPendingConfirmation] = useState<
		number | undefined
	>(undefined);

	const defaultLogicState: BalanceOfPaymentLogicState = {
		bopChangedNavigationPath: "",
		currentPage: undefined,
		showBopChangedModal: false,
		showUnselectedModal: false,
	};
	const [logicState, setLogicState] =
		useStateWithCallback<BalanceOfPaymentLogicState>(defaultLogicState);

	const [bopCategories, setBopCategories] = useState<string[] | undefined>(
		undefined,
	);

	const {
		paymentBops,
		paymentBopsCount,
		isLoading: isPaymentBopsLoading,
	} = useListPaymentBops(paymentId, state.queryParams.payment_bop);

	const onBack = () => {
		if (!transactionId) return;
		navigate(paths().paymentDetails(transactionId));
	};

	const onBopChangedBack = () => {
		setLogicState({
			...logicState,
			bopChangedNavigationPath: "",
			showBopChangedModal: false,
		});
	};

	const onBopChangedConfirm = async () => {
		if (!exchangeDetails) {
			handleGeneralError();
			return;
		}

		if (!bopPendingConfirmation) return;

		setState({ ...state, selectedBopOption: bopPendingConfirmation });
		setIsSaving(true);
		const errors = await updatePaymentBop({
			paymentId: exchangeDetails.payment_ids[0],
			bop: {
				bopId: bopPendingConfirmation,
			},
		});
		setIsSaving(false);

		if (errors) {
			showToast("Failed to update balance of payment", "error");
			return;
		}
		setLogicState({
			...logicState,
			bopChangedNavigationPath: "",
			showBopChangedModal: false,
		});
		mutatePaymentStatus();
		setBopPendingConfirmation(undefined);
	};

	const onChangeCategory = (selectedBopCategory?: string) => {
		let bopCategoryGroup = selectedBopCategory;
		if (bopCategoryGroup === "All") {
			bopCategoryGroup = undefined;
		}
		setState({
			...state,
			queryParams: {
				...state.queryParams,
				payment_bop: {
					...state.queryParams.payment_bop,
					bop_category_group: bopCategoryGroup,
				},
			},
		});
	};

	const onChangeFilterString = (
		filterString?: string,
		immediateSearch?: boolean,
	) => {
		const newState = { ...state };
		newState.queryParams.payment_bop.search_query = filterString ?? "";

		if (filterString === "") {
			newState.queryParams.payment_bop.bop_category_group = undefined;
			setLogicState({ ...logicState, currentPage: 1 });
		}

		if (immediateSearch) {
			setLogicState({ ...logicState, currentPage: 1 });
		}
		setState(newState);
	};

	const onChangeSelected = async (selectedBopOption?: number) => {
		if (!exchangeDetails) {
			handleGeneralError();
			return;
		}

		if (!selectedBopOption) return;

		const isUpdatedBoP =
			state.existingBopOption !== undefined &&
			selectedBopOption !== state.existingBopOption &&
			hasUploadedDocuments;
		if (isUpdatedBoP) {
			setBopPendingConfirmation(selectedBopOption);
			setLogicState({
				...logicState,
				showBopChangedModal: true,
				bopChangedNavigationPath: "",
			});
		} else {
			setState({ ...state, selectedBopOption });
			setIsSaving(true);

			const errors = await updatePaymentBop({
				paymentId: exchangeDetails.payment_ids[0],
				bop: {
					bopId: selectedBopOption,
				},
			});

			if (errors) {
				showToast("Failed to update balance of payment", "error");
				return;
			}

			mutatePaymentStatus();
			setIsSaving(false);
		}
	};

	const onCloseUnselectedModal = () => {
		setLogicState({ ...logicState, showUnselectedModal: false });
	};

	const onNext = (navigationPath?: string) => {
		if (!transactionId) return;
		const nextNavigationPath =
			navigationPath ?? paths().documents(transactionId);

		if (!exchangeDetails) {
			handleGeneralError();
			return;
		}

		if (nextNavigationPath.includes(paths().paymentDetails(transactionId))) {
			navigate(nextNavigationPath);
			return;
		}

		if (state.selectedBopOption) {
			navigate(nextNavigationPath);
		} else {
			setLogicState({ ...logicState, showUnselectedModal: true });
		}
	};

	const onPageChange = (page: number, rowsPerPage: number) => {
		setState({
			...state,
			queryParams: {
				...state.queryParams,
				payment_bop: {
					...state.queryParams.payment_bop,
					limit: rowsPerPage,
					offset: (page - 1) * rowsPerPage,
				},
			},
		});

		setLogicState({ ...logicState, currentPage: page });
	};

	const onSearch = () => {
		setLogicState({ ...logicState, currentPage: 1 });
	};

	const handleResumeLater = async () => {
		if (!state.selectedBopOption || !exchangeDetails) return;
		await updatePaymentBop({
			paymentId: exchangeDetails.payment_ids[0],
			bop: {
				bopId: state.selectedBopOption,
			},
		});
	};

	const onSortByName = (directionString?: string) => {
		setState({
			...state,
			queryParams: {
				...state.queryParams,
				payment_bop: {
					...state.queryParams.payment_bop,
					ordering: directionString,
				},
			},
		});
	};

	const scrollToSelected = useCallback(() => {
		const selectedRowElement =
			document.getElementsByClassName(selectedRowClassName);
		const topPos = (selectedRowElement?.item(0) as HTMLElement)?.offsetTop ?? 0;

		const tableElement = document
			.getElementById(tableId)
			?.getElementsByClassName("p-datatable-wrapper");
		const headerElement = document
			.getElementById(tableId)
			?.getElementsByClassName("p-datatable-thead");
		const headerHeight = headerElement?.item(0)?.clientHeight ?? 0;

		if (tableElement) {
			const firstElement = tableElement?.item(0);
			if (firstElement) {
				firstElement.scrollTop = topPos - headerHeight;
			}
		}
	}, []);

	useEffect(() => {
		if (paymentBop && paymentBops) {
			setState((currentState) => ({
				...currentState,
				existingBopOption: paymentBop.id,
				selectedBopOption: paymentBop.id,
				queryParams: {
					...currentState.queryParams,
					payment_id: paymentId,
				},
			}));
			scrollToSelected();
		}
	}, [paymentId, paymentBop, scrollToSelected, paymentBops]);

	useEffect(() => {
		setBopCategories(paymentBopOptions?.listBopCategoryGroups);
	}, [paymentBopOptions]);

	const isLoading =
		isSuggestedPaymentBopsLoading ||
		isPaymentBopOptionsLoading ||
		isPaymentStatusLoading ||
		isPaymentBopLoading ||
		isSupportingDocumentsLoading;

	return (
		<BalanceOfPaymentView
			currentPage={logicState.currentPage}
			filterString={state.queryParams.payment_bop.search_query}
			listPaymentBops={paymentBops}
			loading={isLoading}
			loadingTable={isPaymentBopsLoading}
			popularBopSuggestions={suggestedPaymentBops?.popular}
			recentBopSuggestions={suggestedPaymentBops?.recentlyUsed}
			selectedBopCategory={state.queryParams.payment_bop.bop_category_group}
			selectedBopOption={state.selectedBopOption}
			existingBopOption={state.existingBopOption}
			selectedRowClassName={selectedRowClassName}
			showBopChangedModal={logicState.showBopChangedModal}
			showUnselectedModal={logicState.showUnselectedModal}
			paymentBopCategories={
				state.queryParams.payment_bop.bop_category_group && !isMobile
					? ["All", ...(bopCategories ?? [])]
					: bopCategories
			}
			paymentStatus={
				paymentStatus ? tempPaymentStatusMapping(paymentStatus) : undefined
			}
			tableId={tableId}
			tableTotal={paymentBopsCount ?? 0}
			paymentBopDetails={paymentBop}
			isSaving={isSaving}
			onBack={onBack}
			onBopChangedBack={onBopChangedBack}
			onBopChangedConfirm={onBopChangedConfirm}
			onNavigateTransaction={(navigationPath: string) => {
				if (!transactionId) return;

				if (
					navigationPath.includes(paths().documents(transactionId)) &&
					!state.selectedBopOption
				) {
					setLogicState({ ...logicState, showUnselectedModal: true });
				} else {
					navigate(navigationPath);
				}
			}}
			onChangeCategory={onChangeCategory}
			onChangeFilterString={onChangeFilterString}
			onChangeSelected={onChangeSelected}
			onCloseUnselectedModal={onCloseUnselectedModal}
			accountManager={accountManager}
			onResumeLater={handleResumeLater}
			onSortByName={onSortByName}
			onSearch={onSearch}
			onNext={onNext}
			onPageChange={onPageChange}
		/>
	);
};
