import { api } from "@app/services";
import { convertBlobToBase64 } from "@app/utils";
import { getFilenameFromHeader } from "@app/utils/get-filename-from-header";
import { getFormErrors } from "@app/utils/get-form-errors";
import useSWR from "swr";

export type SupportingDocument = {
	document_type_id: number;
	document_type: string;
	documents: Array<{
		id: number;
		document_name: string;
		document_extension: string;
		document_size: string;
	}>;
};

export const useSupportingDocuments = (paymentId?: number) => {
	const { data, isLoading, mutate, error } = useSWR<Array<SupportingDocument>>(
		paymentId ? `/payments/${paymentId}/supporting-documents/` : null,
	);

	return {
		data,
		isLoading,
		mutate,
		error,
		createSupportingDocument: async (body: {
			paymentId: number;
			supportingDocument: {
				documentTypeId: number;
				file: File;
			};
			onUploadProgress?: (progressEvent: any) => void;
		}) => {
			try {
				const formData = new FormData();
				formData.append(
					"document_type_id",
					body.supportingDocument.documentTypeId.toString(),
				);
				formData.append("file", body.supportingDocument.file);
				const { data } = await api.post(
					`payments/${body.paymentId}/supporting-documents/`,
					formData,
					{
						onUploadProgress: body.onUploadProgress,
					},
				);
				return [
					{
						documentId: data.document_id,
					},
					null,
				] as const;
			} catch (error) {
				return [null, getFormErrors(error)] as const;
			}
		},
		deleteSupportingDocument: async (documentId: number) => {
			try {
				await api.delete(`/supporting-documents/${documentId}/`);
			} catch (error) {
				return getFormErrors(error);
			}
		},
		downloadSupportingDocument: async (documentId: number) => {
			try {
				const { data, headers } = await api.get(
					`supporting-documents/${documentId}/`,
					{
						responseType: "blob",
					},
				);

				const fileName = getFilenameFromHeader(
					headers,
					`Supporting Document ${documentId}.pdf`,
				);
				const contentType = headers["content-type"];

				return [
					{
						fileName: fileName,
						contentType: contentType,
						data: await convertBlobToBase64(data),
					},
					null,
				] as const;
			} catch (error) {
				return [null, getFormErrors(error)] as const;
			}
		},
	};
};
