import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { paths } from "@app/constants/paths";
import { useSetCurrentPage } from "@app/hooks/use-set-payment-current-page";
import { downloadFile } from "@app/utils";

import { CustomLoader } from "@app/components/custom-loader";
import { NeedHelp } from "@app/components/need-help";
import { TransactionDetailsFooter } from "../transaction-details-footer";

import { useAccountManager } from "@app/hooks/use-account-manager";
import {
	SupportingDocument,
	useSupportingDocuments,
} from "@app/hooks/use-supporting-documents";
import { useTransaction } from "@app/hooks/use-transaction";
import { useTransactionId } from "@app/hooks/use-transaction-id";
import { TransactionLayout, TransactionStep } from "../transaction-layout";
import { useSubmittedTransactionRedirect } from "../use-submitted-transaction-redirect";
import { DocumentTypeContent } from "./document-type-content";
import styles from "./index.module.css";
import { handleGeneralError } from "@app/utils/handle-general-error";
import { useBopDocuments } from "@app/hooks/use-bop-documents";
import { isSuperTooltip, SuperTooltip } from "./super-tooltip";
import { useShowToast } from "@app/components/toasts/use-show-toast";

const Documents = () => {
	const [isSaving, setIsSaving] = useState(false);
	const [showResumeLater, setShowResumeLater] = useState(false);
	const { data: accountManager } = useAccountManager();
	const transactionId = useTransactionId();
	const [showToast] = useShowToast();
	const [isDownloading, setIsDownloading] = useState(false);
	const {
		exchangeDetails,
		isExchangeDetailsLoading,
		isPaymentStatusLoading,
		activePaymentId,
	} = useTransaction(transactionId);
	const {
		data: supportingDocuments,
		isLoading: isSupportingDocumentsLoading,
		mutate: mutateSupportingDocuments,
		deleteSupportingDocument,
		createSupportingDocument,
		downloadSupportingDocument,
	} = useSupportingDocuments(activePaymentId);

	const { data: bopDocuments, isLoading: isBopDocumentsLoading } =
		useBopDocuments(activePaymentId);

	const navigate = useNavigate();

	const [showValidation, setShowValidation] = useState(false);

	const checkRequiredDocumentsUploaded = () => {
		const requiredDocumentsUploaded = bopDocuments?.every(
			(x) =>
				!x.required ||
				supportingDocuments?.some(
					(y) => y?.document_type_id === x?.document_type_id,
				),
		);

		setShowValidation(true);
		return requiredDocumentsUploaded;
	};

	const handleDeleteDocument = async (documentId?: number | string) => {
		if (documentId !== undefined) {
			const safeDocumentId =
				typeof documentId === "string" ? +documentId : documentId;

			if (!Number.isNaN(safeDocumentId)) {
				setIsSaving(true);
				const errors = await deleteSupportingDocument(safeDocumentId);
				setIsSaving(false);

				if (errors) {
					showToast("Failed to delete document", "error");
					return;
				}
				mutateSupportingDocuments();
			}
		}
	};

	const onDownloadDocument = async (
		document: SupportingDocument["documents"][number],
	) => {
		setIsDownloading(true);
		const [response, errors] = await downloadSupportingDocument(document.id);
		setIsDownloading(false);

		if (errors || !response) {
			showToast("Failed to download document", "error");
			return;
		}

		downloadFile(
			response.contentType,
			response.data,
			document.document_name || new Date().toLocaleString(),
		);
	};

	const handleNext = (navigationPath?: string) => {
		if (exchangeDetails) {
			if (checkRequiredDocumentsUploaded()) {
				navigate(
					navigationPath ??
						paths().reviewTransaction(exchangeDetails.transaction_id),
				);
			}
		} else {
			handleGeneralError();
		}
	};

	const onNavigateTransaction = (navigationPath: string) => {
		if (!transactionId) return;
		if (navigationPath.includes(paths().reviewTransaction(transactionId))) {
			handleNext(navigationPath);
		} else {
			navigate(navigationPath);
		}
	};

	useSubmittedTransactionRedirect();
	useSetCurrentPage(exchangeDetails?.payment_ids[0], "supporting_documents");

	useEffect(() => {
		if (!isExchangeDetailsLoading && !exchangeDetails) {
			handleGeneralError({
				message: "No exchange details found.",
			});
			return;
		}
	}, [exchangeDetails, isExchangeDetailsLoading]);

	const isLoading =
		isDownloading ||
		isExchangeDetailsLoading ||
		isBopDocumentsLoading ||
		isPaymentStatusLoading ||
		isSupportingDocumentsLoading;

	return (
		<TransactionLayout
			step={TransactionStep.Documents}
			onStepNavigate={onNavigateTransaction}
			onResumeLater={() => setShowResumeLater(true)}
			footer={
				<TransactionDetailsFooter
					hasAutoSave
					isSaving={isSaving}
					showResumeLater={showResumeLater}
					onResumeLaterChange={setShowResumeLater}
					onBack={() => {
						if (exchangeDetails?.transaction_id)
							navigate(
								paths().balanceOfPayment(exchangeDetails.transaction_id),
							);
					}}
					onNext={handleNext}
				/>
			}
		>
			<div className={styles.container}>
				<h2 className={styles.title}>Documents</h2>
				<div className={styles.list}>
					{bopDocuments?.map((document) => (
						<DocumentTypeContent
							createSupportingDocument={createSupportingDocument}
							key={document.document_name}
							allDocumentTypeIds={bopDocuments.map(
								(current) => current.document_type_id,
							)}
							tooltip={
								isSuperTooltip(document.document_name) ? (
									<SuperTooltip documentName={document.document_name} />
								) : (
									document.description
								)
							}
							document={{
								documentName: document.document_name,
								documentTypeId: document.document_type_id,
								required: document.required,
								description: document.description,
							}}
							showValidation={showValidation}
							paymentSupportingDocs={supportingDocuments}
							onDownloadDocument={onDownloadDocument}
							onDeleteDocument={handleDeleteDocument}
							exchangeDetails={exchangeDetails}
							mutateSupportingDocuments={mutateSupportingDocuments}
						/>
					))}
				</div>
				<div className={styles.divider} />
				<NeedHelp
					email={accountManager?.email}
					phone={accountManager?.contact_number}
				>
					Need help with supporting documents?
				</NeedHelp>
			</div>
			{isLoading && <CustomLoader />}
		</TransactionLayout>
	);
};

export default Documents;
