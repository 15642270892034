import { useState } from "react";

import { paths } from "@app/constants/paths";
import { Link, useLocation } from "react-router-dom";
import { HomeIcon } from "./home-icon";
import { RecipientsIcon } from "./recipients-icon";
import { SendReceiveIcon } from "./send-receive-icon";
import { TransactionsIcon } from "./transactions-icon";
import AccountsIcon from "./accounts.svg?react";
import PlusIcon from "./plus.svg?react";
import AccountsTransactionsIcon from "./transactions.svg?react";

import { isPWA } from "@app/config/env";
import { useProfilePicture } from "@app/hooks/use-profile-picture";
import { AccountMenuDialog } from "../account-menu-dialog";
import { ProfilePicture } from "../profile-picture";
import styles from "./index.module.css";
import { useClients } from "@app/hooks/use-clients";
import { isAccountsEnabled } from "@app/constants/feature-flags";

const isActive = (pathname: string, route: string) => {
	if (route === paths().dashboard) return pathname === route;
	return pathname.startsWith(route);
};

export const BottomMenuBar = () => {
	const [showAccountMenu, setShowAccountMenu] = useState(false);
	const location = useLocation();
	const { count } = useClients();
	const { data } = useProfilePicture();

	const isAccountsPage = location.pathname.startsWith(paths().accounts);

	const hasMultipleClients =
		isAccountsEnabled && typeof count === "number" && count > 1;

	return (
		<>
			<nav aria-label="Main">
				<ul className={styles.container} data-is-pwa={isPWA}>
					{isAccountsPage ? (
						<>
							<li className={styles.item}>
								<Link
									aria-current={isActive(location.pathname, paths().accounts)}
									className={styles.link}
									to={paths().accounts}
								>
									<div className={styles.indicator} />
									<AccountsIcon width={24} height={24} />
									Accounts
								</Link>
							</li>
							<li className={styles.placeholderItem}>
								<AccountsTransactionsIcon width={24} height={24} />
								Transactions <br />
								(coming soon)
							</li>
							<li className={styles.item}>
								<Link
									aria-current={isActive(
										location.pathname,
										paths().sendReceiveFunds(),
									)}
									className={`${styles.link} ${styles.button}`}
									aria-label="Add new account"
									to={paths().onboarding.individual.addClient}
								>
									<PlusIcon width={24} height={24} />
								</Link>
							</li>
							<li className={styles.item} />
							<li className={styles.item}>
								<button
									type="button"
									className={styles.link}
									onClick={() => setShowAccountMenu(!showAccountMenu)}
									aria-current={isActive(
										location.pathname,
										paths().userSettings(),
									)}
								>
									<div className={styles.indicator} />
									<ProfilePicture src={data?.base64} />
									Account
								</button>
							</li>
						</>
					) : (
						<>
							<li className={styles.item}>
								<Link
									aria-current={isActive(location.pathname, paths().dashboard)}
									className={styles.link}
									to={paths().dashboard}
								>
									<div className={styles.indicator} />
									<HomeIcon />
									Home
								</Link>
							</li>
							<li className={styles.item}>
								<Link
									aria-current={isActive(
										location.pathname,
										paths().transactions(),
									)}
									className={styles.link}
									to={paths().transactions()}
								>
									<div className={styles.indicator} />
									<TransactionsIcon />
									Transactions
								</Link>
							</li>
							<li className={styles.item}>
								<Link
									aria-current={isActive(
										location.pathname,
										paths().sendReceiveFunds(),
									)}
									className={`${styles.link} ${styles.button}`}
									aria-label="Send or receive funds"
									to={paths().sendReceiveFunds()}
								>
									<SendReceiveIcon />
								</Link>
							</li>
							<li className={styles.item}>
								<Link
									aria-current={isActive(location.pathname, paths().recipients)}
									className={styles.link}
									to={paths().recipients}
								>
									<div className={styles.indicator} />
									<RecipientsIcon />
									Recipients
								</Link>
							</li>
							<li className={styles.item}>
								<button
									type="button"
									className={styles.link}
									onClick={() => setShowAccountMenu(!showAccountMenu)}
									aria-current={isActive(
										location.pathname,
										paths().userSettings(),
									)}
								>
									<div className={styles.indicator} />
									<ProfilePicture src={data?.base64} />
									Account
								</button>
							</li>
						</>
					)}
				</ul>
			</nav>
			<AccountMenuDialog
				hasMultipleClients={hasMultipleClients}
				isOpen={showAccountMenu}
				onClose={() => setShowAccountMenu(false)}
			/>
		</>
	);
};
