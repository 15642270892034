import { dateFormats } from "@app/constants/date-formats";
import { toDayjs } from "@app/lib/date";
import { api } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import useSWR from "swr";

export interface PaymentApn {
	reference_number: string;
	issue_date: string;
	is_editable: boolean;
}

export const usePaymentApn = (paymentId?: number) => {
	const { data, isLoading, error, mutate } = useSWR<PaymentApn>(
		paymentId ? `/payments/${paymentId}/apn-details/` : null,
	);

	return {
		data,
		isLoading,
		error,
		mutate,
		createPaymentApn: async (
			paymentId: number,
			apn: {
				referenceNumber?: string;
				issueDate?: Date;
			},
		) => {
			try {
				await api.post(`payments/${paymentId}/apn-details/`, {
					issue_date: apn.issueDate
						? toDayjs(apn.issueDate).format(dateFormats.iso8601)
						: undefined,
					reference_number: apn.referenceNumber,
				});
				mutate();
			} catch (errors) {
				return getFormErrors(errors);
			}
		},
	};
};
