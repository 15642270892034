import { useMemo } from "react";
import useSWR from "swr";

export const usePaymentBopOptions = (paymentId?: number) => {
	const { data, isLoading, error } = useSWR<{
		allowed_methods: string[];
		allowed_headers: string[];
		list_bop_category_groups: string[];
		ordering: string[];
	}>(paymentId ? `payments/${paymentId}/bops/options/` : null);

	const result = useMemo(
		() =>
			data
				? {
						allowedHeaders: data.allowed_headers,
						allowedMethods: data.allowed_methods,
						listBopCategoryGroups: data.list_bop_category_groups,
						ordering: data.ordering,
					}
				: undefined,
		[data],
	);
	return {
		data: result,
		isLoading,
		error,
	};
};
