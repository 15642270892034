import type { GetPaymentBopDocs } from "@app/entities";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { useState } from "react";

import { Button } from "@app/components/button";
import { FieldError } from "@app/components/field-error";
import { IconButton } from "@app/components/icon-button";
import { TextTruncate } from "@app/components/text-truncate";
import { formatFileSize } from "@app/utils/format-file-size";
import clsx from "clsx";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import styles from "./index.module.css";

import { SupportingDocument } from "@app/hooks/use-supporting-documents";

import additionalDocumentSrc from "./additional-document.svg";
import uploadDocumentSrc from "./upload-document.svg";
import uploadedDocumentSrc from "./uploaded-document.svg";
import { ReactNode } from "react";
import { ContentTooltip } from "./content-tooltip";
import { DocumentUploadModal } from "@app/components/document-upload-modal";
import { DocumentUploadCandidate } from "@app/entities";
import { abortRequests } from "@app/services";
import { isSuperTooltip, SuperTooltip } from "../super-tooltip";
import { handleGeneralError } from "@app/utils/handle-general-error";
import { AxiosProgressEvent } from "axios";
import { FormErrors } from "@app/utils/get-form-errors";

const ADDITIONAL_DOCUMENTS_TYPE_ID = 1;

export const DocumentTypeContent = ({
	document,
	showValidation,
	paymentSupportingDocs,
	onDownloadDocument,
	onDeleteDocument,
	allDocumentTypeIds,
	tooltip,
	exchangeDetails,
	mutateSupportingDocuments,
	createSupportingDocument,
}: {
	tooltip?: ReactNode;
	document: GetPaymentBopDocs;
	showValidation: boolean;
	paymentSupportingDocs?: SupportingDocument[] | null;
	onDownloadDocument: (doc: SupportingDocument["documents"][number]) => void;
	onDeleteDocument: (id?: string | number) => void;
	allDocumentTypeIds: number[];
	exchangeDetails: any;
	mutateSupportingDocuments: () => void;
	createSupportingDocument: (body: {
		paymentId: number;
		supportingDocument: {
			documentTypeId: number;
			file: File;
		};
		onUploadProgress: (progressEvent: AxiosProgressEvent) => void;
	}) => Promise<
		readonly [null, FormErrors] | readonly [{ readonly documentId: any }, null]
	>;
}) => {
	const isMobile = useMediaQuery();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [candidates, setCandidates] = useState<DocumentUploadCandidate[]>([]);

	const supportingDocuments = paymentSupportingDocs
		?.filter((current) => {
			const isMatchingType =
				current.document_type_id === document.documentTypeId;
			const isUngroupedDoc =
				document.documentTypeId === ADDITIONAL_DOCUMENTS_TYPE_ID &&
				!allDocumentTypeIds.includes(current.document_type_id);

			return isMatchingType || isUngroupedDoc;
		})
		.flatMap((current) => current.documents);

	const isEmpty = !supportingDocuments || supportingDocuments.length === 0;
	const isInError = document.required && showValidation && isEmpty;

	const iconSrc = isEmpty
		? document.required
			? uploadDocumentSrc
			: additionalDocumentSrc
		: uploadedDocumentSrc;

	const isEmptyWithoutTooltip = isEmpty && !tooltip;
	const isNodeTooltip = tooltip && typeof tooltip !== "string";

	const handleShowModal = () => {
		const documentCandidates =
			supportingDocuments?.map<DocumentUploadCandidate>((x) => {
				return {
					existing: true,
					file: undefined,
					fileExtension: x.document_extension,
					fileName: x.document_name,
					fileSize: x.document_size ? Number.parseFloat(x.document_size) : 0,
					id: x.id,
					load: 0,
				};
			}) || [];

		setCandidates(documentCandidates);
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
		setCandidates([]);
		mutateSupportingDocuments();
	};

	const handleConfirmUpload = () => {
		setIsModalOpen(false);
		setCandidates([]);
		mutateSupportingDocuments();
	};

	const handleCancelUpload = () => {
		abortRequests();
	};

	const handleUpload = async (
		index: number,
		file: File | undefined,
		onSetUploadComplete: (index: number, id?: number | string) => void,
		onSetUploadProgress: (progressEvent: AxiosProgressEvent) => void,
		onUploadError: (error: string, index: number) => void,
	) => {
		if (exchangeDetails) {
			if (file && document.documentTypeId) {
				const [response, errors] = await createSupportingDocument({
					paymentId: exchangeDetails.payment_ids[0],
					supportingDocument: {
						documentTypeId: document.documentTypeId,
						file: file,
					},
					onUploadProgress: onSetUploadProgress,
				});

				if (errors) {
					const errorMessage = errors.apiErrors[0] ?? "Upload failed";
					onUploadError(errorMessage, index);
					return;
				}

				onSetUploadComplete(index, response?.documentId);
				return;
			}
		} else {
			handleGeneralError();
		}
	};

	return (
		<>
			<div
				className={styles.container}
				data-error={isInError}
				data-empty={isEmpty}
			>
				<div
					className={styles.wrapper}
					data-is-empty-without-tooltip={isEmptyWithoutTooltip}
				>
					<img
						className={styles.icon}
						src={iconSrc}
						width={24}
						height={24}
						alt=""
					/>
					<div className={styles.content}>
						<div className={styles.titleContainer}>
							<div
								className={styles.titleWrapper}
								data-vertical={isEmpty || isNodeTooltip}
							>
								<p className={styles.title}>
									{document?.required
										? `${document?.documentName}*`
										: `${document?.documentName} (optional)`}
								</p>
								<ContentTooltip
									documentName={document.documentName}
									tooltip={tooltip}
									isEmpty={isEmpty}
								/>
							</div>
							{isMobile && (
								<IconButton
									variant="primary"
									className={clsx(isEmpty && styles.addButton)}
									onClick={handleShowModal}
								>
									<FiPlus size={24} color="inherit" />
								</IconButton>
							)}
						</div>

						{supportingDocuments && supportingDocuments?.length > 0 && (
							<ul className={styles.list}>
								{supportingDocuments.map((x) => (
									<li key={x.id} className={styles.listItem}>
										<div className={styles.itemWrapper}>
											{isMobile && (
												<p className={styles.documentName}>
													<TextTruncate text={x.document_name} maxWidth={280} />
												</p>
											)}
											<div className={styles.item}>
												{!isMobile && (
													<>
														<p>
															<TextTruncate
																text={x.document_name}
																maxWidth={224}
															/>
														</p>
														<div className={styles.inlineDivider} />
													</>
												)}
												<p className={styles.fileSize}>
													{formatFileSize(
														x.document_size
															? Number.parseFloat(x.document_size)
															: 0,
													)}
												</p>
												<div className={styles.inlineDivider} />
												<Button
													noPadding
													variant="tertiary"
													className={styles.inlineButton}
													inline
													onClick={() => {
														if (onDownloadDocument) onDownloadDocument(x);
													}}
												>
													Download
												</Button>

												{!isMobile && (
													<>
														<div className={styles.inlineDivider} />
														<Button
															noPadding
															variant="tertiary"
															inline
															className={styles.inlineButton}
															onClick={() => {
																if (onDeleteDocument) onDeleteDocument(x.id);
															}}
														>
															Remove
														</Button>
													</>
												)}
											</div>
										</div>
										{isMobile && (
											<IconButton>
												<FiTrash2
													size={24}
													color="#56A7A2"
													onClick={() => {
														if (onDeleteDocument) onDeleteDocument(x.id);
													}}
												/>
											</IconButton>
										)}
									</li>
								))}
							</ul>
						)}
					</div>
				</div>
				{!isMobile && (
					<Button
						variant={isEmpty ? "primary" : "secondary"}
						inline
						onClick={handleShowModal}
					>
						{isEmpty ? "Add file(s)" : "Change file(s)"}
					</Button>
				)}
			</div>
			{isInError && <FieldError>This document is required</FieldError>}

			<DocumentUploadModal
				isOpen={isModalOpen}
				title={document?.documentName ?? ""}
				candidates={candidates}
				description={
					isSuperTooltip(document?.documentName) ? (
						<SuperTooltip
							variant="modal"
							documentName={document?.documentName}
						/>
					) : (
						document?.description
					)
				}
				multiple
				showSingleButton
				onCancelUpload={handleCancelUpload}
				onClose={handleCloseModal}
				onConfirmUpload={handleConfirmUpload}
				onDeleteItem={(_, item) => onDeleteDocument(item.id)}
				onUpload={handleUpload}
			/>
		</>
	);
};
