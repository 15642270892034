import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import RemoveIcon from "@app/assets/images/trash.svg";
import { Button } from "@app/components/button";
import { HamburgerMenu } from "@app/components/hamburger-menu";
import { paths } from "@app/constants/paths";
import type { FilterRecipientsByForm, ListRecipient } from "@app/entities";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { useStateWithCallback } from "@app/hooks/use-state-with-callback";

import { FiCalendar, FiEdit2, FiSend } from "react-icons/fi";
import type { RecipientTableModel } from "./models/models";
import type {
	RecipientsLogicState,
	RecipientsState,
} from "./models/recipients-state";
import { RecipientsView } from "./recipients-view";
import { RecipientsViewResponsive } from "./recipients-view.responsive";

import { MappedCurrency } from "@app/hooks/use-currencies";
import {
	RecipientsQuery,
	tempRecipientMap,
	useRecipients,
} from "@app/hooks/use-recipients";
import { removeEmptyFields } from "@app/utils/remove-empty-fields";
import styles from "./index.module.css";
import { RecipientCurrencies } from "./recipient-currencies";
import { useShowToast } from "@app/components/toasts/use-show-toast";
import { useRecipientId } from "@app/hooks/use-recipient-id";

const filterGroupSize = 3;

export interface RecipientsQueryState {
	currencies?: string[];
	countries?: string[];
	sortOrder: number;
	sortField: string;
	recipient_name?: string;
	limit?: string;
	offset?: string;
	search?: string;
}

const mapRecipientsStateToQuery = (
	state: RecipientsQueryState,
): Partial<RecipientsQuery> =>
	removeEmptyFields({
		currencies: state.currencies?.join(","),
		countries: state.countries?.join(","),
		ordering:
			state.sortOrder === 1 || state.sortOrder === -1
				? `${state.sortOrder === -1 ? "-" : ""}${state.sortField}`
				: undefined,
		recipient_name: state.recipient_name,
		limit: state.limit ? Number.parseInt(state.limit, 10) : undefined,
		offset: state.offset ? Number.parseInt(state.offset, 10) : undefined,
	});

const Recipients = () => {
	const isMobile = useMediaQuery();
	const navigate = useNavigate();
	const [showToast] = useShowToast();

	const { control } = useForm<FilterRecipientsByForm>({});

	const [state, setState] = useState<RecipientsQueryState>({
		limit: "10",
		offset: "0",
		search: "",
		sortField: "recipient_name",
		sortOrder: 1,
	});

	const {
		recipients,
		isLoading: isRecipientsLoading,
		mutate,
		deleteRecipient,
	} = useRecipients(mapRecipientsStateToQuery(state));

	const defaultRecipientsState: RecipientsState = {
		countries: [],
		currencies: [],
	};

	const [appliedFilters, setAppliedFilters] =
		useStateWithCallback<RecipientsState>(defaultRecipientsState);

	const [currentFilters, setCurrentFilters] =
		useStateWithCallback<RecipientsState>(defaultRecipientsState);

	const [logicState, setLogicState] =
		useStateWithCallback<RecipientsLogicState>({
			currentPage: undefined,
		});

	const [openFilterModal, setOpenFilterModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [deleteId, setDeleteId] = useState<number | undefined>(undefined);
	const [, setRecipientId] = useRecipientId();

	const onNavigatePage = (
		id: number,
		destination: "sendFunds" | "edit" | "history",
	) => {
		switch (destination) {
			case "sendFunds":
				navigate(paths().sendReceiveFunds(id));
				break;
			case "edit":
				navigate(paths().editRecipient(id));
				break;
			case "history":
				setRecipientId(id);
				navigate(paths().transactions());
				break;
		}
	};

	const onRemoveRecipient = (id: number) => {
		setOpenDeleteModal(true);
		setDeleteId(id);
	};

	const onApplyFilters = () => {
		setLogicState({ ...logicState, currentPage: 1 }, () => {
			setAppliedFilters(
				{
					...appliedFilters,
					countries: currentFilters.countries,
					currencies: currentFilters.currencies,
				},
				(values) => {
					setState({
						...state,
						offset: "0",
						countries: values.countries.map((x) => x),
						currencies: values.currencies.map((x) => x.currencyCode),
					});
					if (isMobile) {
						setOpenFilterModal(false);
					}
				},
			);
		});
	};

	const onClearFilters = () => {
		setCurrentFilters(defaultRecipientsState, () => {
			setAppliedFilters(defaultRecipientsState, () => {
				setLogicState({ ...logicState, currentPage: 1 }, () => {
					setState({
						...state,
						search: "",
						recipient_name: "",
						offset: "0",
						countries: defaultRecipientsState.countries.map((x) => x),
						currencies: defaultRecipientsState.currencies?.map(
							(x) => x.currencyCode,
						),
					});
				});
			});
		});
	};

	const onPageChange = (page: number, rowsPerPage: number) => {
		setState({
			...state,
			limit: rowsPerPage.toString(),
			offset: ((page - 1) * rowsPerPage).toString(),
		});

		setLogicState({ ...logicState, currentPage: page });
	};

	const onAdd = () => navigate(paths().addRecipient);

	const onFilterByName = (value: string) => {
		setLogicState({ ...logicState, currentPage: 1 }, () => {
			setState({
				...state,
				offset: "0",
				recipient_name: value,
			});
		});
	};

	const onSortByName = (sortOrder: number, sortField: string) => {
		setState({
			...state,
			sortField,
			sortOrder,
		});
	};

	const onChangeCountryFilters = (value: string[]) => {
		setCurrentFilters({ ...currentFilters, countries: value });
	};

	const onChangeCurrencyFilters = (value: MappedCurrency[]) => {
		setCurrentFilters({ ...currentFilters, currencies: value });
	};

	const onRemoveCountryFilterTag = (value?: string, runFilter?: boolean) => {
		if (!value) {
			return;
		}
		const newState: RecipientsState = {
			...(runFilter ? appliedFilters : currentFilters),
			countries: runFilter
				? [...appliedFilters.countries]
				: [...currentFilters.countries],
		};

		const found = newState.countries.find((x) => x === value);

		if (found) {
			if (runFilter) {
				setAppliedFilters(
					{
						...newState,
						countries: appliedFilters.countries.filter((x) => x !== found),
					},
					(values) => {
						setState({
							...state,
							countries: values.countries.map((x) => x),
						});
					},
				);
			}
			setCurrentFilters({
				...currentFilters,
				countries: currentFilters.countries.filter((x) => x !== found),
			});
		}
	};

	const onRemoveCountryFiltersTag = (value?: string[]) => {
		setCurrentFilters({ ...currentFilters, countries: [] }, (values) => {
			setAppliedFilters({ ...appliedFilters, countries: [] }, (values) => {
				setState({
					...state,
					countries: values.countries.map((x) => x),
				});
			});
		});
	};

	const onRemoveCurrencyFilterTag = (
		value?: MappedCurrency,
		runFilter?: boolean,
	) => {
		if (!value) {
			return;
		}
		const newState: RecipientsState = {
			...(runFilter ? appliedFilters : currentFilters),
			currencies: runFilter
				? [...appliedFilters.currencies]
				: [...currentFilters.currencies],
		};

		const found = newState.currencies.find(
			(x) => x.currencyCode === value.currencyCode,
		);

		if (found) {
			if (runFilter) {
				setAppliedFilters(
					{
						...newState,
						currencies: appliedFilters.currencies.filter(
							(x) => x.currencyCode !== found.currencyCode,
						),
					},
					(values) => {
						setState({
							...state,
							currencies: values.currencies.map((x) => x.currencyCode),
						});
					},
				);
			}
			setCurrentFilters({
				...currentFilters,
				currencies: currentFilters.currencies.filter(
					(x) => x.currencyCode !== found.currencyCode,
				),
			});
		}
	};

	const onRemoveCurrencyFiltersTag = (value?: MappedCurrency[]) => {
		setCurrentFilters({ ...currentFilters, currencies: [] }, (values) => {
			setAppliedFilters({ ...appliedFilters, currencies: [] }, (values) => {
				setState({
					...state,
					currencies: values.currencies.map((x) => x.currencyCode),
				});
			});
		});
	};

	const onFilterClosed = () => {
		setOpenFilterModal(false);
	};

	const onDelete = async () => {
		const errors = await deleteRecipient(deleteId || 0);
		if (errors) {
			showToast("Error removing recipient", "error");
			return;
		}
		setState({ ...state });
		setOpenDeleteModal(false);
		showToast("Recipient removed", "success");
		mutate();
	};

	const mapToTableRowModel = (
		recipient: ListRecipient,
	): RecipientTableModel => {
		return {
			country: recipient.country,
			currencies: recipient.currencies,
			currencyDisplay: <RecipientCurrencies currncies={recipient.currencies} />,
			id: recipient.id,
			lastTransactionAmount: recipient.lastTransactionAmount,
			lastTransactionDate: recipient.lastTransactionDate,
			lastTransactionDisplay: (
				<>
					{recipient.lastTransactionDate && recipient.lastTransactionAmount ? (
						<p className={styles.lastTransaction}>
							{recipient.lastTransactionDate}
							<span>-</span>
							{recipient.lastTransactionAmount}
						</p>
					) : (
						<div className="recipients-empty-table-cell">-</div>
					)}
				</>
			),
			name: recipient.name,
			menu: (
				<HamburgerMenu
					items={[
						{
							icon: (
								<div className={styles.item}>
									<FiSend size={20} color="#56a7a2" />
									Send funds
								</div>
							),
							command: () => {
								navigate(paths().sendReceiveFunds(recipient.id));
							},
						},
						{
							icon: (
								<div className={styles.item}>
									<FiEdit2 size={20} color="#56a7a2" />
									Edit
								</div>
							),
							command: () => {
								navigate(paths().editRecipient(recipient.id));
							},
						},
						{
							icon: (
								<div className={styles.item}>
									<FiCalendar size={20} color="#56a7a2" />
									History
								</div>
							),
							command: () => {
								setRecipientId(recipient.id);
								navigate(paths().transactions());
							},
						},
						{
							className: "final-item-hamburger",
						},
						{
							icon: (
								<div className={styles.item}>
									<img src={RemoveIcon} alt="" />
									Remove
								</div>
							),
							command: () => {
								setOpenDeleteModal(true);
								setDeleteId(recipient.id);
							},
						},
					]}
				/>
			),
			view: (
				<Button
					variant="secondary"
					size="sm"
					onClick={() => {
						navigate(paths().viewRecipient(recipient.id));
					}}
				>
					View
				</Button>
			),
		};
	};

	useEffect(() => {
		if (openFilterModal) {
			setCurrentFilters({
				countries: [...appliedFilters.countries],
				currencies: [...appliedFilters.currencies],
			});
		}
	}, [openFilterModal]);

	const viewProps = {
		appliedFilters,
		currentFilters,
		control,
		currentPage: logicState.currentPage,
		filterGroupSize,
		recipients: recipients?.items.map((recipient) =>
			mapToTableRowModel(tempRecipientMap(recipient)),
		),
		loading: isRecipientsLoading,
		openDeleteModal,
		openFilterModal,
		state,
		total: recipients?.count,
		onAdd,
		onApplyFilters,
		onClearFilters,
		onDelete,
		onFilterByName,
		onFilterClosed,
		onPageChange,
		onChangeCountryFilters,
		onChangeCurrencyFilters,
		onRemoveCountryFilterTag,
		onRemoveCountryFiltersTag,
		onRemoveCurrencyFilterTag,
		onRemoveCurrencyFiltersTag,
		onSortByName,
		setOpenDeleteModal,
		setOpenFilterModal,
		onNavigatePage,
		onRemoveRecipient,
	};

	if (isMobile) {
		return <RecipientsViewResponsive {...viewProps} />;
	}
	return <RecipientsView {...viewProps} />;
};

export default Recipients;
