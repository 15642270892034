import { api } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import useSWR from "swr";

export interface Payment {
	payment_reference: string;
	purpose_of_payment: string;
	proof_of_payment_ready?: boolean;
	fee_type: string;
	swift_amount: string;
	swift_fee: string;
	swift_fee_tooltip: string;
	recipient_id?: number; // Only on send payments
	amount_due?: string; // Only on send payments
}
export const usePayment = (paymentId?: number) => {
	const { data, isLoading, error, mutate } = useSWR<Payment>(
		paymentId ? `/payments/${paymentId}` : null,
	);

	return {
		data,
		isLoading,
		error,
		mutate,
		updatePaymentFields: async (paymentFields: {
			paymentId?: number;
			paymentReference?: string;
			recipient?: number;
			feeType?: string;
			purposeOfPayment?: string;
		}) => {
			try {
				await api.patch(`payments/${paymentFields.paymentId ?? paymentId}/`, {
					payment_reference: paymentFields.paymentReference,
					recipient: paymentFields.recipient,
					fee_type: paymentFields.feeType,
					purpose_of_payment: paymentFields.purposeOfPayment,
				});
				mutate();
			} catch (error) {
				return getFormErrors(error);
			}
		},
		updatePayment: async (payment: {
			paymentId?: number;
			paymentReference?: string;
			purposeOfPayment?: string;
			recipient?: number;
			feeType?: string;
		}) => {
			try {
				await api.put(`payments/${payment.paymentId ?? paymentId}/`, {
					payment_reference: payment.paymentReference,
					recipient: payment.recipient,
					fee_type: payment.feeType,
					purpose_of_payment: payment.purposeOfPayment,
				});
				mutate();
			} catch (error) {
				return getFormErrors(error);
			}
		},
	};
};
