import { Button } from "@app/components/button";
import { Typography } from "@app/components/typography";
import { useMediaQuery } from "@app/hooks/use-media-query";

import { Dialog } from "@app/components/dialog";
import { useWithdrawalBankAccountOptions } from "../use-withdrawal-bank-account-options";
import "./remove-bank-account-modal.css";
import { useWithdrawalBankAccounts } from "../use-withdrawal-bank-accounts";

export const RemoveBankAccountModal = ({
	bankAccountId,
	isOpen,
	onClose,
	onBack,
	onRemove,
}: {
	isOpen: boolean;
	onClose: () => void;
	onBack: () => void;
	onRemove: (bankAccountId: number) => void;
	bankAccountId?: number;
}) => {
	const isMobile = useMediaQuery();

	const { withdrawalBankAccounts } = useWithdrawalBankAccounts();
	const withdrawalBankAccountOptionsResult = useWithdrawalBankAccountOptions();

	const bankAccount = withdrawalBankAccounts?.find(
		(bankAccount) => bankAccount.id === bankAccountId,
	);

	return (
		<Dialog
			isOpen={isOpen}
			onClose={onClose}
			onBack={isMobile ? undefined : onBack}
			fullscreen={isMobile}
			title="Remove account"
			className="remove-bank-account-modal"
			actions={
				<>
					<Button onClick={onBack} variant="secondary">
						Cancel
					</Button>
					<Button
						onClick={() => {
							if (!bankAccountId) return;
							onRemove(bankAccountId);
						}}
					>
						Remove
					</Button>
				</>
			}
		>
			{bankAccount && (
				<div className="remove-bank-account-modal-details-section">
					<div className="remove-bank-account-modal-bank-account">
						<Typography
							className="remove-bank-account-modal-account-detail-label"
							theme="textSm"
						>
							Bank:
						</Typography>
						<Typography
							className="remove-bank-account-modal-account-detail-value"
							theme="textLg"
						>
							{bankAccount.bank}
						</Typography>
					</div>
					<div className="remove-bank-account-modal-account-number-type">
						<Typography
							className="remove-bank-account-modal-account-detail-label"
							theme="textSm"
						>
							Account Number:
						</Typography>
						<Typography
							className="remove-bank-account-modal-account-detail-value"
							theme="textLg"
						>
							{bankAccount.account_number}
						</Typography>
					</div>
					<div className="remove-bank-account-modal-account-number-type">
						<Typography
							className="remove-bank-account-modal-account-detail-label"
							theme="textSm"
						>
							Account Type:
						</Typography>
						<Typography
							className="remove-bank-account-modal-account-detail-value"
							theme="textLg"
						>
							{withdrawalBankAccountOptionsResult.data?.account_types?.find(
								(current) => current.value === bankAccount?.account_type,
							)?.name ?? bankAccount?.account_type}
						</Typography>
					</div>
				</div>
			)}
		</Dialog>
	);
};
