import type { DropdownProps } from "primereact/dropdown";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { FormBuilder } from "@app/components/form-builder";
import { Typography } from "@app/components/typography";
import type {
	AccountType,
	AddEditBankAccountForm,
	DropdownOption,
	LocalBank,
	UpdateWithdrawalBankAccount,
	WithdrawalBankAccount,
} from "@app/entities";

import { useClients } from "@app/hooks/use-clients";
import { api, MappedReasons } from "@app/services";
import { useWithdrawalBankAccountOptions } from "../use-withdrawal-bank-account-options";

import { useClientProfile } from "@app/hooks/use-client-profile";
import { MappedCurrency } from "@app/hooks/use-currencies";
import { useMediaQuery } from "@app/hooks/use-media-query";
import styles from "./index.module.css";
import { Dialog } from "@app/components/dialog";
import { Button } from "@app/components/button";
import { StandardOTPFlow } from "@app/features/otp/standard-otp-flow";
import { FormInputProps } from "@app/components/form-builder/types";
import { getIsOTPError } from "@app/features/otp/get-is-otp-error";
import { ConfirmedModal } from "@app/components/confirmed-modal";
import { isOTPAuthEnabled } from "@app/constants/feature-flags";
import { useShowToast } from "@app/components/toasts/use-show-toast";
import { useWithdrawalBankAccounts } from "../use-withdrawal-bank-accounts";

export interface LogicState {
	mappedReasons?: MappedReasons;
}

export const AddEditBankAccountModal = ({
	isEdit,
	editBankAccountID,
	isOpen,
	onBack,
	onClose,
	onSave,
}: {
	isEdit?: boolean;
	editBankAccountID?: number;
	isOpen: boolean;
	onBack: () => void;
	onClose: () => void;
	onSave: () => void;
}) => {
	const [showStandardOTPFlow, setShowStandardOTPFlow] = useState(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [showConfirmedModal, setShowConfirmedModal] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const isMobile = useMediaQuery();
	const [data, setData] = useState<AddEditBankAccountForm>();
	const {
		control,
		watch,
		handleSubmit,
		formState: { errors },
		reset,
		getValues,
	} = useForm<AddEditBankAccountForm>({
		mode: "onTouched",
	});
	const { createAccount, updateAccount } = useWithdrawalBankAccounts();
	const [showToast] = useShowToast();

	const { activeClient } = useClients();
	const { data: clientProfile } = useClientProfile();
	const withdrawalBankAccountOptionsResult = useWithdrawalBankAccountOptions();

	const [logicState, setLogicState] = useState<LogicState>();

	const getUserDetails = useCallback(async () => {
		const values = getValues();

		let withdrawalBankAccount: WithdrawalBankAccount | undefined;
		if (editBankAccountID && isEdit) {
			const { data } = await api.get(
				`withdrawal-bank-accounts/${editBankAccountID}/`,
			);
			withdrawalBankAccount = {
				id: data.id,
				bank: data.bank,
				accountNumber: data.account_number,
				accountType: data.account_type,
			};
		}

		let resetValues = {
			...values,
			accountNumber:
				isEdit && withdrawalBankAccount
					? withdrawalBankAccount.accountNumber
					: "",
			accountType:
				isEdit && withdrawalBankAccount
					? withdrawalBankAccount.accountType
					: "",
			bankAccount:
				isEdit && withdrawalBankAccount ? withdrawalBankAccount.bank : "",
		};

		if (clientProfile?.entity_type === "individual") {
			resetValues = {
				...resetValues,
				firstName: clientProfile.first_name,
				lastName: clientProfile.last_name,
			};
		} else if (clientProfile?.entity_type === "legal_entity" && activeClient) {
			resetValues = {
				...resetValues,
				name: activeClient.name,
			};
		}

		reset(resetValues);
	}, [
		clientProfile,
		activeClient,
		getValues,
		isEdit,
		editBankAccountID,
		reset,
	]);

	const getBankAccounts = (): string[] => {
		if (withdrawalBankAccountOptionsResult.data?.local_banks) {
			return withdrawalBankAccountOptionsResult.data?.local_banks
				.map((x: LocalBank) => {
					return x.name;
				})
				.filter((x): x is string => x !== undefined);
		}
		return [];
	};

	const getBankAccountTypes = () => {
		if (withdrawalBankAccountOptionsResult.data?.account_types) {
			return withdrawalBankAccountOptionsResult.data.account_types.map<DropdownOption>(
				(accountType: AccountType) => {
					return {
						label: accountType.name || "",
						value: accountType.value || "",
					};
				},
			);
		}
		return [];
	};

	const accountTypesValueTemplate = (
		option: DropdownOption,
		dropdownProps: DropdownProps | MappedCurrency,
	) => {
		if (option?.label) {
			return <Typography theme="textMd">{option.label}</Typography>;
		}

		return <>{(dropdownProps as DropdownProps).placeholder}</>;
	};

	const selectedBank =
		withdrawalBankAccountOptionsResult.data?.local_banks?.find(
			(item) => item.name === watch("bankAccount"),
		);

	const selectAccountType =
		withdrawalBankAccountOptionsResult.data?.account_types.find(
			(accountType) => {
				return accountType.value === watch("accountType");
			},
		);

	const handleSubmitRequest = async (dataOverride?: AddEditBankAccountForm) => {
		const data = dataOverride ?? getValues();
		if (!dataOverride) {
			setData(data);
		}
		if (data.accountNumber && data.accountType && data.bankAccount) {
			const selectedBank =
				withdrawalBankAccountOptionsResult.data?.local_banks?.find(
					(item) => item.name === data.bankAccount,
				);

			const dataAsAddEditDetails: UpdateWithdrawalBankAccount = {
				accountNumber: data.accountNumber,
				accountType: data.accountType,
				bankId: selectedBank?.id,
				bankAccountId: editBankAccountID,
			};

			setIsSubmitting(true);
			if (isEdit) {
				const errors = await updateAccount(dataAsAddEditDetails);
				setIsSubmitting(false);
				if (errors) {
					if (getIsOTPError(errors.apiErrors)) {
						setShowConfirmationModal(false);
						setShowStandardOTPFlow(true);
						return;
					}

					setLogicState({
						...logicState,
						mappedReasons: errors.mappedReasons,
					});
					return;
				}

				setLogicState({
					...logicState,
					mappedReasons: undefined,
				});
				if (isOTPAuthEnabled) {
					setShowConfirmedModal(true);
				} else {
					showToast("Account edited", "success");
					onSave();
				}
			} else {
				const errors = await createAccount(dataAsAddEditDetails);
				setIsSubmitting(false);
				if (errors) {
					if (getIsOTPError(errors.apiErrors)) {
						setShowConfirmationModal(false);
						setShowStandardOTPFlow(true);
						return;
					}

					setLogicState({
						...logicState,
						mappedReasons: errors.mappedReasons,
					});
					return;
				}

				setLogicState({
					...logicState,
					mappedReasons: undefined,
				});

				if (isOTPAuthEnabled) {
					setShowConfirmedModal(true);
				} else {
					showToast("Account added", "success");
					onSave();
				}
			}
		}
	};

	const handleFormSubmit = () => {
		setShowConfirmationModal(true);
	};

	const firstNameField: FormInputProps = {
		name: "firstName",
		placeholder: "",
		showLabel: true,
		title: "First Name",
		hideAsterisk: true,
		onChange: () => {},
		className: styles.disabled,
		disabled: true,
	};

	const lastNameField: FormInputProps = {
		name: "lastName",
		placeholder: "",
		showLabel: true,
		title: "Last Name",
		hideAsterisk: true,
		onChange: () => {},
		className: styles.disabled,
		disabled: true,
	};

	const nameField: FormInputProps = {
		name: "name",
		placeholder: "",
		showLabel: true,
		title: "Name",
		hideAsterisk: true,
		onChange: () => {},
		className: styles.disabled,
		disabled: true,
	};
	const fields =
		clientProfile?.entity_type === "individual"
			? isMobile
				? [[firstNameField], [lastNameField]]
				: [[firstNameField, lastNameField]]
			: [[nameField]];

	const bankAccountInputs: FormInputProps[][] = [
		...fields,
		[
			{
				name: "bankAccount",
				className: "add-edit-bank-account-dropdown",
				options: getBankAccounts(),
				placeholder: "Select bank",
				required: true,
				filter: true,
				showLabel: true,
				title: "Bank",
				hideAsterisk: true,
				type: "dropdown",
				onChange: () => {},
				panelClassName: "add-edit-bank-account-dropdown-panel",
				iconColour: "#888",
				iconSize: 20,
			},
		],
		[
			{
				name: "accountNumber",
				placeholder: "Enter account number",
				required: true,
				showLabel: true,
				title: "Account number",
				inputMode: "numeric",
				hideAsterisk: true,
				className: styles.input,
			},
		],
		[
			{
				name: "accountType",
				options: getBankAccountTypes(),
				valueTemplate: accountTypesValueTemplate,
				itemTemplate: accountTypesValueTemplate,
				className: "add-edit-bank-account-dropdown",
				placeholder: "Select account type",
				required: true,
				filter: true,
				showLabel: true,
				title: "Account Type",
				hideAsterisk: true,
				type: "dropdown-option",
				onChange: () => {},
				panelClassName: "add-edit-bank-account-dropdown-panel",
				iconColour: "#888",
				iconSize: 20,
			},
		],
	];

	useEffect(() => {
		if (isOpen) {
			getUserDetails();
		}
	}, [isOpen, getUserDetails]);

	return (
		<>
			<Dialog
				onBack={isMobile ? undefined : onBack}
				isOpen={
					isOpen &&
					!showConfirmationModal &&
					!showStandardOTPFlow &&
					!showConfirmedModal
				}
				onClose={onClose}
				fullscreen={isMobile}
				showTopbar={isMobile}
				title={isEdit ? "Edit withdrawal account" : "Add withdrawal account"}
				actions={
					<>
						<Button
							disabled={isSubmitting}
							variant="secondary"
							onClick={onBack}
						>
							Back
						</Button>
						<Button
							disabled={isSubmitting}
							form="add-edit-bank-account-form"
							type="submit"
						>
							Save
						</Button>
					</>
				}
			>
				<form
					id="add-edit-bank-account-form"
					onSubmit={handleSubmit(handleFormSubmit)}
					noValidate
				>
					<div>
						<p className={styles.title}>Account Holder</p>
						<p className={styles.subtitle}>
							You can only withdraw to a bank account in your name.
						</p>
						<FormBuilder
							formControl={control}
							errors={errors}
							formInputs={bankAccountInputs}
							mappedReasons={logicState?.mappedReasons}
						/>
					</div>
				</form>
			</Dialog>
			<Dialog
				isOpen={showConfirmationModal && isOpen}
				onBack={() => setShowConfirmationModal(false)}
				onClose={onClose}
				size="xl"
				fullscreen={isMobile}
				showTopbar={isMobile}
				title="Confirm changes"
				actions={
					<>
						<Button
							disabled={isSubmitting}
							variant="secondary"
							onClick={() => setShowConfirmationModal(false)}
						>
							Back
						</Button>
						<Button
							disabled={isSubmitting}
							onClick={() => handleSubmitRequest()}
						>
							Confirm
						</Button>
					</>
				}
			>
				<div className={styles.summary}>
					<p className={styles.summaryLabel}>Bank:</p>
					<p>{selectedBank?.name}</p>
					<div className={styles.summaryRow}>
						<div className={styles.summaryRowItem}>
							<p className={styles.summaryLabel}>Account Number:</p>
							<p>{watch("accountNumber")}</p>
						</div>
						<div className={styles.summaryRowItem}>
							<p className={styles.summaryLabel}>Account Type:</p>
							<p>{selectAccountType?.name}</p>
						</div>
					</div>
				</div>
			</Dialog>

			{showConfirmedModal && (
				<ConfirmedModal
					title={
						isEdit ? "Withdrawal account updated" : "Withdrawal account added"
					}
					description={
						isEdit
							? "Withdrawals to this account will use the updated details."
							: "You can now withdraw ZAR to this bank account"
					}
					onClose={onSave}
				/>
			)}

			{showStandardOTPFlow && isOpen && (
				<StandardOTPFlow
					onClose={() => {
						setShowStandardOTPFlow(false);
						setShowConfirmationModal(true);
					}}
					onComplete={async () => {
						await handleSubmitRequest(data);
						setShowStandardOTPFlow(false);
					}}
				/>
			)}
		</>
	);
};
