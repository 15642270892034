import { twMerge } from "tailwind-merge";

import { Button } from "@app/components/button";
import { Dropdown } from "@app/components/controls";
import { CustomLoader } from "@app/components/custom-loader";
import { FormBuilder } from "@app/components/form-builder";
import { NeedHelp } from "@app/components/need-help";
import { SignedInLayout } from "@app/components/signed-in-layout";
import { Tag } from "@app/components/tag";
import { Typography } from "@app/components/typography";
import type {
	ForexQuote,
	GetSettlementAccountSingular,
	TransactionDirection,
} from "@app/entities";
import { useMediaQuery } from "@app/hooks/use-media-query";

import "./create-quote.css";

import { CountryIcon } from "@app/components/country-icon";

import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { Note } from "@app/components/note";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";

import { AccountManager } from "@app/hooks/use-account-manager";
import { MappedCurrency } from "@app/hooks/use-currencies";
import { ForexQuoteOptions } from "@app/hooks/use-quote-options";
import { SettlementAccount } from "@app/hooks/use-settlement-account-options";
import type { MappedReasons } from "@app/services";
import type { Control, FieldErrors } from "react-hook-form";
import { useSystemStatus } from "../../hooks/use-system-status";
import styles from "./index.module.css";
import { OutOfHoursModal } from "./out-of-hours-modal";
import { SendReceiveButtonGroup } from "./send-receive-button-group";
import { ValueDateSelect } from "./value-date-select";
import { FormInputProps } from "@app/components/form-builder/types";
import { showMappedReasons } from "@app/components/form-builder/show-mapped-reasons";
import { isMobileTrayMenuEnabled } from "@app/constants/feature-flags";
import { SingleSelectDialog } from "@app/components/single-select-dialog";
import { AvailableBalanceDisplay } from "./available-balance-display";
import { SettlementAccountDropdown } from "./settlement-account-dropdown";
import { LegalDisclaimer } from "@app/components/legal-disclaimer";

export interface QuoteState {
	errors: string[];
	transactionType?: TransactionDirection;
	fxCurrency?: string;
	fxAmount?: string;
	quoteAmount?: string;
	quoteCurrency?: string;
	valueDate?: string;
	paramId?: number;
	forexQuoteData?: ForexQuote;
	rate?: string;
	settlementAccounts?: GetSettlementAccountSingular;
}

export const CreateQuoteView = (props: {
	detailsFormInputs?: FormInputProps[][];
	errors?: FieldErrors<any>;
	formControl: Control<any, any>;
	activeClientId?: number;
	isValid: boolean;
	loading?: boolean;
	isCreatingQuote?: boolean;
	mappedReasons: MappedReasons;
	transactionType?: TransactionDirection;
	title: string;
	valueDate?: string;
	business?: boolean;
	inputFields: FormInputProps[][];
	fxinputFields: FormInputProps[][];
	accountManager?: AccountManager;
	currencies?: string[];
	quoteOptions?: ForexQuoteOptions;
	currenciesMapped: MappedCurrency[];
	forexQuote?: ForexQuote;
	settlementAccounts?: SettlementAccount[];
	showOutOfHoursModal?: boolean;
	fxCurrency?: string;
	onCancel: () => void;
	onSaveQuote: () => void;
	onBack: () => void;
	onSelectTransactionType: (transactionType: TransactionDirection) => void;
	onSelectValueDate: (date: string) => void;
	onOutOfHours: () => void;
	onChangeFxCurrency: (value: string) => void;
	onValidate: (type: "all" | "transaction" | "value-dates") => boolean;
	onChangeSettlementAccount: (account: number) => void;
	state: QuoteState;
}) => {
	const isMobile = useMediaQuery();
	const systemStatusResult = useSystemStatus();
	const countryIconSize = isMobile ? 24 : 32;

	const getSelectedValueTemplate = (option: string) => {
		const mapped = props.currenciesMapped.find(
			(x) => x.currencyCode === option,
		);
		return mapped ? (
			<span className={styles.selectedValue}>
				<CountryIcon
					width={countryIconSize}
					height={countryIconSize}
					currencyCode={option}
				/>
				<p>{mapped.currencyCode}</p>
			</span>
		) : (
			<Typography theme="textMd" className="my-auto text-gray-300">
				Currency
			</Typography>
		);
	};

	const getQuoteRow = () => {
		const isSend = props.transactionType === "send";
		const paymentTypeStyle = twMerge(
			isSend
				? "border border-blue-200 bg-blue-50 flex justify-center"
				: "border border-purple-200 bg-purple-50 flex justify-center",
		);

		return (
			<>
				<div
					className={`flex items-center gap-1 rounded-10px p-3 lg:p-5 ${paymentTypeStyle}`}
				>
					{isSend ? (
						<FiArrowDown size={24} color="#262626" />
					) : (
						<FiArrowUp size={24} color="#262626" />
					)}
				</div>
				<div className="vertical-divider" />
				<div>
					<p className={styles.exchangeRate}>Exchange Rate</p>
					<p className={styles.exchangeRateValue}>
						{props.forexQuote?.quotePageDisplay
							? props.forexQuote?.quotePageDisplay
							: "-"}
					</p>
				</div>
			</>
		);
	};

	return (
		<SignedInLayout
			title="Send/Receive funds"
			hasBackButton
			hideBottomNav
			footer={
				<div className="add-quote-footer">
					<Button onClick={() => props.onCancel()} variant="secondary">
						Cancel
					</Button>
					<Button
						disabled={props.isCreatingQuote}
						onClick={() => props.onSaveQuote()}
					>
						Continue
					</Button>
				</div>
			}
		>
			<div className="add-quote-root">
				<header className="header">
					<Typography className="title" theme="displayMd">
						Send/Receive funds
					</Typography>
				</header>

				<div className="flex w-full flex-col items-start gap-4 mobile:px-6">
					<div
						className="mt-6 lg:mt-0 flex w-full flex-row justify-start gap-2"
						data-data-pr-position="right"
						data-pr-classname="break-normal w-full"
					>
						<Typography
							className={twMerge("font-semibold", "text-gray-1100")}
							theme="textLg"
						>
							What type of transaction is this?
						</Typography>
						{props.transactionType && (
							<MoreInfoTooltip hasIcon maxWidth={400} name="Transaction type">
								<Typography theme="textSm" className="flex flex-col">
									<div className="tooltip-row">
										<strong>Send</strong> - Send foreign currency out of South
										Africa.
									</div>
									<div className="tooltip-row">
										<strong>Receive</strong> - Receive foreign currency into
										South Africa.
									</div>
								</Typography>
							</MoreInfoTooltip>
						)}
					</div>
					<SendReceiveButtonGroup
						value={props.transactionType}
						onChange={(transactionType) => {
							props.onSelectTransactionType(
								transactionType as TransactionDirection,
							);
						}}
					/>
				</div>
				{!props.transactionType && (
					<Tag
						containerClassName="flex flex-col items-start w-full gap-4 mobile:px-6 p-0"
						tagStyle="custom"
						className="bg-gray-50 px-5 py-2.5 mobile:w-full"
					>
						<div className="flex w-71 flex-col">
							<div className="tooltip-row">
								<span>Send</span> - Send foreign currency out of South Africa.
							</div>
							<div className="tooltip-row">
								<span>Receive</span> - Receive foreign currency into South
								Africa.
							</div>
						</div>
					</Tag>
				)}

				<div className="divider-wrapper">
					<div className="divider" />
				</div>

				{props.transactionType && (
					<SettlementAccountDropdown
						options={props.settlementAccounts}
						error={showMappedReasons("settlementAccount", props.mappedReasons)}
						value={props.state.settlementAccounts?.bankAccountId}
						onChange={(value) => {
							props.onChangeSettlementAccount(value);
						}}
					/>
				)}

				{props.transactionType ? (
					<div className="quote-section">
						<Typography
							theme="textLg"
							className="flex flex-col items-start font-semibold"
						>
							Transaction Amount
						</Typography>
						<div className="flex w-full flex-col gap-10px">
							<div className={styles.headerRow}>
								<Typography theme="textSm" className={"font-semibold"}>
									{(props.transactionType || "receive") === "send"
										? "You send"
										: "You receive"}
								</Typography>
								{props.transactionType === "send" && (
									<AvailableBalanceDisplay />
								)}
							</div>
							<div className={styles.field}>
								<div className="selected-item-container">
									{getSelectedValueTemplate("ZAR")}
								</div>
								<FormBuilder
									errors={props.errors}
									formTitle={props.title}
									formInputs={props.inputFields}
									formControl={props.formControl}
								/>
								<div />
								<div className="error-field-highlight">
									{!isMobile
										? showMappedReasons("quoteAmount", props.mappedReasons)
										: null}
								</div>
							</div>
							{isMobile
								? showMappedReasons("quoteAmount", props.mappedReasons)
								: null}
						</div>
						<div className={styles.exchangeRow}>{getQuoteRow()}</div>
						<div className="flex w-full flex-col gap-10px">
							<Typography theme="textSm" className={"font-semibold"}>
								{(props.transactionType || "receive") === "send"
									? "They receive"
									: "They send"}
							</Typography>
							<div
								data-currency-error={!!props.mappedReasons.fxCurrency}
								className={styles.field}
							>
								{isMobile && isMobileTrayMenuEnabled ? (
									<SingleSelectDialog
										value={
											props.state.fxCurrency
												? {
														id: props.state.fxCurrency,
														name: props.state.fxCurrency,
														icon: (
															<CountryIcon
																width={countryIconSize}
																height={countryIconSize}
																currencyCode={props.state.fxCurrency}
															/>
														),
													}
												: undefined
										}
										title="Select currency"
										options={
											props.currencies?.map((current) => ({
												id: current,
												name: current,
												icon: (
													<CountryIcon
														width={countryIconSize}
														height={countryIconSize}
														currencyCode={current}
													/>
												),
											})) ?? []
										}
										onChange={(value) =>
											props.onChangeFxCurrency(value.id as string)
										}
										placeholder="Currency"
										className={`${styles.dropdownCurrency}  ${
											props.mappedReasons.fxCurrency
												? "border border-red-450"
												: ""
										}`}
									/>
								) : (
									<Dropdown
										className={`${styles.dropdownCurrency}  ${
											props.mappedReasons.fxCurrency
												? "border border-red-450"
												: ""
										}`}
										filter
										panelClassName={styles.dropdownCurrencyPanel}
										options={props.currencies ?? []}
										value={props.state.fxCurrency}
										valueTemplate={(option) => getSelectedValueTemplate(option)}
										itemTemplate={(option) => {
											const isActive = option === props.state.fxCurrency;
											const mapped = props.currenciesMapped.find(
												(x) => x.currencyCode === option,
											);
											return mapped ? (
												<div className={styles.dropdownCurrencyItem}>
													<div className={styles.dropdownCurrencyItemInner}>
														<CountryIcon
															width={24}
															height={24}
															currencyCode={mapped.currencyCode}
														/>
														<p>
															{mapped?.currencyCode} - {mapped.verboseName}
														</p>
													</div>
													{isActive && (
														<svg
															role="presentation"
															width="20"
															height="20"
															viewBox="0 0 20 20"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M16.6673 5L7.50065 14.1667L3.33398 10"
																stroke="#888"
																strokeWidth="1.66667"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
													)}
												</div>
											) : undefined;
										}}
										onChange={(event: any) => {
											props.onChangeFxCurrency(event);
										}}
									/>
								)}

								<FormBuilder
									errors={props.errors}
									formTitle={props.title}
									formInputs={props.fxinputFields}
									formControl={props.formControl}
								/>

								<div />

								{!isMobile
									? showMappedReasons("fxAmount", props.mappedReasons)
									: null}
							</div>
							{isMobile
								? showMappedReasons("fxAmount", props.mappedReasons)
								: null}

							{showMappedReasons("fxCurrency", props.mappedReasons)}
						</div>
						<Note className={styles.swiftNote} variant="full">
							SWIFT processing fees are excluded.
						</Note>
					</div>
				) : (
					<div className="flex w-full flex-row items-start gap-2 mobile:px-6">
						<Typography
							theme="textLg"
							className="flex flex-col items-start font-semibold text-gray-300"
						>
							Transaction Amount
						</Typography>
					</div>
				)}
				{!isMobile && (
					<div className="divider-wrapper">
						<div className="divider" />
					</div>
				)}

				<div className="flex w-full flex-col items-start gap-4">
					<ValueDateSelect
						transactionType={props.transactionType}
						currency={props.fxCurrency}
						disabledDates={props.forexQuote?.valueDatesDisabled}
						variant={props.business ? "legal_entity" : "individual"}
						value={props.valueDate}
						onChange={props.onSelectValueDate}
						errors={props.mappedReasons.valueDate}
						hasError={
							props.mappedReasons.valueDate &&
							props.mappedReasons.valueDate?.length > 0
						}
					/>
				</div>

				<div className="help-section-container">
					<NeedHelp
						email={props.accountManager?.email}
						phone={props.accountManager?.contact_number}
					>
						Need help with this transaction?
					</NeedHelp>
					<LegalDisclaimer />
				</div>
			</div>
			{props.loading && <CustomLoader />}

			<OutOfHoursModal
				isOpen={
					!systemStatusResult.data?.transactions_enabled ||
					!!props.showOutOfHoursModal
				}
				onClose={props.onOutOfHours}
				onConfirm={props.onOutOfHours}
			/>
		</SignedInLayout>
	);
};
