import { PrimeReactProvider } from "primereact/api";
import { ReactNode } from "react";
import { ToastContainer } from "react-toastify";
import { SWRConfig } from "swr";

import { ConnectionMonitor } from "@app/components/connection-monitor";

import "react-toastify/dist/ReactToastify.min.css";
import "./index.css";

import { fetcher } from "./fetcher";
import { isConnectionMonitorEnabled } from "./constants/feature-flags";

export const Providers = ({ children }: { children: ReactNode }) => (
	<SWRConfig
		value={{
			revalidateOnMount: true,
			revalidateOnFocus: false,
			shouldRetryOnError: false,
			dedupingInterval: 5000,
			fetcher,
		}}
	>
		<PrimeReactProvider>
			{children}
			{isConnectionMonitorEnabled && <ConnectionMonitor />}
		</PrimeReactProvider>
		<ToastContainer autoClose={2000} className="toast-container-position" />
	</SWRConfig>
);
