import { useClients } from "@app/hooks/use-clients";
import { api } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import useSWR from "swr";

export type WithdrawalType = "standard" | "rtc";

export const useWithdrawalBankAccounts = () => {
	const { activeClientId } = useClients();
	const { data, isLoading, error, mutate } = useSWR<
		Array<{
			id: number;
			account_holder: string | null;
			bank: string;
			account_number: string;
			account_type: string;
		}>
	>(
		activeClientId
			? `/clients/${activeClientId}/withdrawal-bank-accounts/`
			: null,
	);
	return {
		withdrawalBankAccounts: data,
		isLoading,
		error,
		mutate,
		deleteAccount: async (bankAccountId: number) => {
			try {
				await api.delete(`withdrawal-bank-accounts/${bankAccountId}/`);
				mutate();
			} catch (error) {
				return getFormErrors(error);
			}
		},
		createAccount: async (account: {
			bankId?: number;
			accountNumber?: string;
			accountType?: string;
			clientId?: number;
		}) => {
			try {
				const payload = {
					bank_id: account.bankId,
					account_number: account.accountNumber,
					account_type: account.accountType,
					client_id: account.clientId,
				};
				await api.post(
					`clients/${activeClientId}/withdrawal-bank-accounts/`,
					payload,
				);
				mutate();
			} catch (error) {
				return getFormErrors(error);
			}
		},
		updateAccount: async (account: {
			bankAccountId?: number;
			bankId?: number;
			accountNumber?: string;
			accountType?: string;
		}) => {
			try {
				const payload = {
					bank_id: account.bankId,
					account_number: account.accountNumber,
					account_type: account.accountType,
				};
				await api.put(
					`withdrawal-bank-accounts/${account.bankAccountId}/`,
					payload,
				);
				mutate();
			} catch (error) {
				return getFormErrors(error);
			}
		},
		createWithdrawalRequest: async (withdrawal: {
			bankId?: number;
			amount?: number;
			withdrawalType?: WithdrawalType;
		}) => {
			try {
				const payload = {
					client_id: activeClientId,
					bank_id: withdrawal.bankId,
					amount: withdrawal.amount,
					withdrawal_type: withdrawal.withdrawalType,
				};
				await api.post("withdrawals/request/", payload);
				mutate();
			} catch (error) {
				return getFormErrors(error);
			}
		},
	};
};
