import { api } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import { useMemo } from "react";
import useSWR from "swr";

export const usePaymentBop = (paymentId?: number) => {
	const { data, isLoading, error, mutate } = useSWR<{
		bop_code: string;
		category: string;
		description: string;
		id: number;
	}>(paymentId ? `payments/${paymentId}/bop/` : null);

	const paymentBop = useMemo(
		() =>
			data
				? {
						bopCode: data.bop_code,
						category: data.category,
						description: data.description,
						id: data.id,
					}
				: undefined,
		[data],
	);

	return {
		paymentBop: paymentBop,
		isLoading,
		error,
		updatePaymentBop: async (data: {
			paymentId: number;
			bop: {
				bopId: number;
			};
		}) => {
			try {
				await api.put(`payments/${data.paymentId}/bop/`, {
					bop_id: data.bop.bopId,
				});
				mutate();
			} catch (error) {
				return getFormErrors(error);
			}
		},
	};
};
