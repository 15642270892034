import { isLocalOrTest, isTest2, isProd, isDemo } from "@app/config/env";

export const isPrimaryShareholdersEnabled = false;

export const isOTPAuthEnabled = true;
export const isAvailableBalanceEnabled = true;
export const isMobileTrayMenuEnabled = true;
export const isOTPErrorFromAPIEnabled = true;
export const isUpdatedResentModalEnabled = true;
export const isRelatedEntityDocumentsEnabled = true;
export const isChangeEmailAddressEnabled = true;
export const isUpdateResendOTPModalEnabled = true;
export const isPostalCodeTypeChangeEnabled = true;

export const isOTPErrorTypeEnabled = isTest2 || isProd || isDemo; //BE has not pushed to the other test env's yet but have pushed to prod

export const isHowDidYouHearAboutUsHiddenForReferringCommissionEarner =
	isLocalOrTest;
export const isConnectionMonitorEnabled = isLocalOrTest;
export const isLegalDisclaimerEnabled = isLocalOrTest;
export const isTrustDeviceSettingsEnabled = isLocalOrTest;
export const isLoginSelectionEnabled = isLocalOrTest;
export const isSuperTooltipEnabled = isLocalOrTest;
export const isAccountsEnabled = isLocalOrTest;
export const isTagSizingUpdateEnabled = isLocalOrTest;
export const isSSOEnabled = isLocalOrTest;
