import { useMemo } from "react";
import useSWR from "swr";

interface SuggestedBopItem {
	id: number;
	bop_code: string;
	category: string;
	description: string;
}

const mapSuggestedPaymentBop = (value: SuggestedBopItem) => {
	return {
		bopCode: value.bop_code,
		category: value.category,
		id: value.id,
		description: value.description,
	};
};

export const useSuggestedPaymentBops = (paymentId?: number) => {
	const { data, isLoading, error } = useSWR<{
		popular: SuggestedBopItem[];
		recently_used: SuggestedBopItem[];
	}>(paymentId ? `payments/${paymentId}/suggested-bops/` : null);

	const suggestedPaymentBops = useMemo(
		() =>
			data
				? {
						popular: data.popular.map(mapSuggestedPaymentBop),
						recentlyUsed: data.recently_used.map(mapSuggestedPaymentBop),
					}
				: undefined,
		[data],
	);

	return {
		suggestedPaymentBops,
		isLoading,
		error,
	};
};
