import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Tag } from "@app/components/tag";
import { pathParams, paths } from "@app/constants/paths";
import {
	type RecipientTransaction,
	type TransactionDirection,
} from "@app/entities";

import { dateFormats } from "@app/constants/date-formats";
import {
	tempCurrenciesMapping,
	useCurrencies,
} from "@app/hooks/use-currencies";
import {
	tempLatestRecipientTransactionMapping,
	useLatestRecipientTransactions,
} from "@app/hooks/use-latest-recipient-transactions";
import { tempMapRecipient, useRecipient } from "@app/hooks/use-recipient";
import { toDayjs } from "@app/lib/date";
import type { ViewRecipientTableModel } from "./models/view-recipient-table-model";
import { ViewButton } from "./view-button";
import { ViewRecipientView } from "./view-recipient-view";
import { useShowToast } from "@app/components/toasts/use-show-toast";
import { useRecipientId } from "@app/hooks/use-recipient-id";
import { PaymentTypeCell } from "@app/components/payment-type-cell";

import styles from "./index.module.css";
import { isTagSizingUpdateEnabled } from "@app/constants/feature-flags";
import { TransactionStatus } from "./transaction-status";

const ViewRecipient = () => {
	const [isDeleting, setIsDeleting] = useState(false);

	const [showToast] = useShowToast();
	const [, setRecipientId] = useRecipientId();

	const { data: currencies } = useCurrencies();
	const navigate = useNavigate();

	const params = useParams();

	const paramId = params[pathParams.id];

	const { data: latestTransactions, isLoading: isLatestTransactionsLoading } =
		useLatestRecipientTransactions(paramId, {
			limit: 5,
		});

	const {
		data,
		isLoading: isRecipientLoading,
		mutate,
		deleteRecipient,
	} = useRecipient(paramId);

	const [recipient, setRecipient] = useState<
		ReturnType<typeof tempMapRecipient>
	>({
		firstName: "",
		lastName: "",
		companyName: "",
		nickname: "",
		entityType: "",
		currencies: "",
		ordering: "-date",
		bankDetails: {
			bankName: "",
			country: "",
			accountNumber: "",
			iban: "",
			swiftCode: "",
			routingNumber: "",
			sortCode: "",
			branch: "",
		},
		address: {
			addressLine1: "",
			addressLine2: "",
			city: "",
			province: "",
			country: "",
			postalCode: "",
			combinedStr: "",
		},
	});
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [openNicknameModal, setOpenNicknameModal] = useState(false);

	const isLoading = !!(
		isDeleting ||
		isLatestTransactionsLoading ||
		isRecipientLoading
	);

	const onClickAddOrEdit = () => setOpenNicknameModal(true);

	const onCloseNicknameModal = (newNickname?: boolean) => {
		setOpenNicknameModal(false);
		if (newNickname && paramId) {
			mutate();
		}
	};

	const onClickDelete = () => setOpenDeleteModal(true);
	const onCloseDeleteModal = () => setOpenDeleteModal(false);

	const onDeleteRecipient = async () => {
		if (paramId) {
			setIsDeleting(true);
			const errors = await deleteRecipient(+paramId);
			setIsDeleting(false);

			if (errors) {
				showToast("Error removing recipient", "error");
				return;
			}

			onCloseDeleteModal();
			navigate(paths().recipients);
			showToast("Recipient removed", "success");
		}
	};

	const onEditRecipient = () => {
		if (paramId) {
			navigate(paths().editRecipient(+paramId));
		}
	};

	const onBack = () => {
		navigate(paths().recipients);
	};

	const onViewAllTransactions = (recipientId?: number) => {
		setRecipientId(recipientId);
		navigate(paths().transactions());
	};

	const mapToTableRowModel = (
		x: RecipientTransaction,
	): ViewRecipientTableModel => {
		const paymentType = x.paymentType.toLowerCase() as TransactionDirection;

		return {
			...x,
			date:
				x.date !== null
					? toDayjs(x.date, dateFormats.reverseIso8601).format(
							dateFormats.paddedDayShortMonthYear,
						)
					: "-",
			fxAmount: x.fxAmount,
			fxAmountDisplay: (
				<>
					{x.fxAmount !== "" ? (
						x.fxAmount
					) : (
						<div className="view-recipient-empty-table-cell">-</div>
					)}
				</>
			),
			tablePaymentType: (
				<div className={styles.centered}>
					<PaymentTypeCell type={paymentType} />
				</div>
			),
			transactionStatus: (
				<div className={styles.centered}>
					{isTagSizingUpdateEnabled ? (
						<TransactionStatus>{x.status}</TransactionStatus>
					) : (
						<Tag
							iconPlacement="left"
							tagStyle={
								x.status.toLowerCase() === "complete"
									? "complete"
									: "inProgress"
							}
							text={x.status}
						/>
					)}
				</div>
			),
			view: <ViewButton transactionId={x.id} />,
			zarAmount: x.zarAmount,
			zarAmountDisplay: (
				<>
					{x.zarAmount !== "" ? (
						x.zarAmount
					) : (
						<div className="view-recipient-empty-table-cell">-</div>
					)}
				</>
			),
		};
	};

	useEffect(() => {
		if (data) {
			setRecipient(tempMapRecipient(data));
		}
	}, [data]);

	return (
		<ViewRecipientView
			currencies={currencies?.currency_mapping.map(tempCurrenciesMapping)}
			latestTransactions={latestTransactions?.items?.map((transaction) =>
				mapToTableRowModel(tempLatestRecipientTransactionMapping(transaction)),
			)}
			loading={isLoading}
			openDeleteModal={openDeleteModal}
			openNicknameModal={openNicknameModal}
			recipient={recipient}
			recipientId={paramId ? +paramId : undefined}
			onBack={onBack}
			onClickAddOrEdit={onClickAddOrEdit}
			mutate={mutate}
			onClickDelete={onClickDelete}
			onCloseNicknameModal={onCloseNicknameModal}
			onCloseDeleteModal={onCloseDeleteModal}
			onSendFunds={() => {
				navigate(paths().sendReceiveFunds(paramId ? +paramId : undefined));
			}}
			onEditRecipient={onEditRecipient}
			onDeleteRecipient={onDeleteRecipient}
			onViewAllTransactions={onViewAllTransactions}
		/>
	);
};

export default ViewRecipient;
