import { toast, ToastPosition, ToastTransition } from "react-toastify";
import { ToastType } from "./toast-variants";
import { types } from "./toast-variants";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { ToastContent } from "./toast-content";

export const useShowToast = (
	props: {
		autoClose?: number;
		className?: string;
		content?: ReactNode;
		icon?: ReactNode;
		iconClassName?: string;
		showCloseButton?: boolean;
		transition?: ToastTransition;
		onClose?: () => void;
	} = {},
) => {
	const isMobile = useMediaQuery();

	const showToast = (message: string, type: ToastType, toastId?: string) => {
		const theme = types[(type as keyof typeof types) ?? "success"];

		const currentToastId =
			toastId ??
			message
				.split("")
				.reduce((acc, char) => acc + char.charCodeAt(0), 0)
				.toString();

		const toastOptions = {
			autoClose: (theme.persistent ? false : (props.autoClose ?? 5000)) as
				| number
				| false,
			className: twMerge(theme.className, props.className),
			closeButton: theme.persistent ? false : (props.showCloseButton ?? false),
			hideProgressBar: true,
			closeOnClick: !theme.persistent,
			icon: false,
			position: (isMobile ? "bottom-center" : "top-right") as ToastPosition,
			transition: props.transition,
			toastId,
			onClose: () => {
				toast.dismiss(toastId);
				props.onClose?.();
			},
		};

		if (!toast.isActive(currentToastId)) {
			toast.info(
				<ToastContent
					{...props}
					content={<span>{message}</span>}
					theme={theme}
				/>,
				toastOptions,
			);
		}
	};

	return [showToast];
};
