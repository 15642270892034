import { downloadFile } from "@app/utils";

import { useBopDocuments } from "@app/hooks/use-bop-documents";
import {
	SupportingDocument,
	useSupportingDocuments,
} from "@app/hooks/use-supporting-documents";
import { useTransaction } from "@app/hooks/use-transaction";
import { DocumentsDetailsBlockView } from "./documents-details-block-view";
import { useShowToast } from "@app/components/toasts/use-show-toast";

export const DocumentsDetailsBlock = ({
	transactionId,
	onEdit,
}: {
	transactionId?: number;
	onEdit?: () => void;
}) => {
	const [showToast] = useShowToast();
	const { activePaymentId } = useTransaction(transactionId);
	const { data: paymentRequiredDocs, isLoading: isPaymentRequiredDocsLoading } =
		useBopDocuments(activePaymentId);
	const {
		data: paymentSupportingDocs,
		isLoading: paymentSupportingDocsLoading,
		downloadSupportingDocument,
	} = useSupportingDocuments(activePaymentId);

	return (
		<DocumentsDetailsBlockView
			documents={paymentSupportingDocs}
			requiredDocuments={paymentRequiredDocs}
			loading={paymentSupportingDocsLoading || isPaymentRequiredDocsLoading}
			onEdit={onEdit}
			onDownloadDoc={async (docs: SupportingDocument) => {
				for (const doc of docs.documents) {
					const documentName = doc.document_name;

					const [response, errors] = await downloadSupportingDocument(doc.id);

					if (errors) {
						showToast("Failed to download document", "error");
						return;
					}
					downloadFile(
						response.contentType,
						response.data,
						documentName || new Date().toLocaleString(),
					);
				}
			}}
		/>
	);
};
